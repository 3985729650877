import React, { useEffect, useState } from 'react'
import HScreen from '../layout/hScreen'
import { useParams, useNavigate } from 'react-router-dom'
import { LineMenu } from '../components/LineMenu'
import { ByProject } from '../components/ByProject'
import { ByRepo } from '../components/ByRepo'
import { Button } from '../components/Button'
import { useHttp } from '../hooks/http.hook'
import { useSelector } from 'react-redux'
import {
  AcademicCapIcon,
} from '@heroicons/react/outline'
import { useGit } from '../hooks/git.hook'



export const Project = (data) => {
  const auth = useSelector(state => state.auth)
  const history = useNavigate()
  const params = useParams()
  const id = params.id
  const type = params.type
  const [page, setPage] = useState(id)
  const { loading } = useHttp()
  const [project, setProject] = useState({})
  const { gitRequest } = useHttp()
  const { getSingleProject } = useGit()

  useEffect(() => {
    setPage(id)
  }, [id])

  const Statistics = () => {
    switch (params['*']) {
      case "byrepo":
        return (
          <ByRepo id={id} />
        )
      case "bycommand":
        return (
          <span>by command</span>
        )


      case "byproject":
        return (
          <ByProject id={id} />
        )


    }
  }



  useEffect(() => {
    try {
      (async () => {
       
        if (id !== "project") {
          let tmpObj = await getSingleProject(id, auth)
          setProject(tmpObj)
        }

      })()

    } catch (e) {
      console.log(e)
    }
  }, [id])




  useEffect(() => {
    try {
      (async () => {
        const getProject = await gitRequest(`/api/v4/projects/${id}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setProject({
          id: getProject.id,
          name: getProject.name,
          path: getProject.path,
          path_with_namespace: getProject.path_with_namespace
        })
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])



  const Repo = ({ projects = [], ...props }) => {
    return (
      <>

        <div className="flex flex-col ">
          <div>
            <p className='font-normal text-base'>Список репозиториев</p>
            {/* <ul className='mt-3'>{listOfRepo}</ul> */}
          </div>


          <div className='inline-flex mt-[17px] font-normal text-base hover:text-[#94A3B8] cursor-pointer' onClick={() => {
            history(`/user/repo/${id}/${project.name}/content`)
          }}>
            <AcademicCapIcon className='w-[24px]' /> <p className='ml-[7px]'>{project.name}</p>
          </div>


          {/* {projects.length ?
            <div className='flex flex-col items-center gap-6 px-0 w-full'>
              <Button
                // size="slim"
                color="secondary"
                className="w-full"
                onClick={() => { history('/project/create') }}
                disabled={loading}
              >
                Создать новый репозиторий
              </Button>
            </div>
            :
            <div className='mt-[30%] flex flex-col items-center gap-6 px-0 w-full'>
              <span className="text-center">У вас нет ни одного репозитория, для<br />начала работы вам необходимо<br />создать его.</span>
              <Button
                // size="slim"
                color="secondary"
                className="w-full"
                onClick={() => { history(`/project/create`) }}
                disabled={loading}
              >
                Создать новый репозиторий
              </Button>
            </div>
          } */}
        </div>
      </>
    )
  }

  return (
    <HScreen
      project={{
        id: id
      }}
    >
      <div className="flex h-screen">
        <div className="flex w-1/4 px-6 py-4">
          <Repo projects={[]} />
        </div>
        <div className="flex w-3/4 py-4 px-[54px] bg-slate-50">
          <div className="flex flex-col w-full gap-[40px]">
            <div className="flex flex-col gap-4">
              <LineMenu items={
                [
                  { id: 0, text: 'По проектам', href: `/user/project/${id}/byproject` },
                  { id: 1, text: 'По репозиториям', href: `/user/project/${id}/byrepo` },
                  // { id: 2, text: 'По участникам команд', href: `/user/project/${id}/bycommand` },
                ]
              } />
              <Statistics id={page} />
            </div>
          </div>
        </div>
      </div>
    </HScreen>
  )
}