import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { logout } from '../../redux/actions/auth.actions'
import HScreen from '../../layout/hScreen'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import Input from '../../components/Input'
import { Button } from '../../components/Button'

export const UserPasswordPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    password: '',
    newPassword: '',
    retypePassword: ''
  })
  const { loading, request, error, clearError } = useHttp()
  const message = useMessage()
  const history = useNavigate()

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    message('Check and Update password')
    // try {
    //   // check pass newpass = retypePass && length > 0
    //   if (!form.newPassword || form.newPassword !== form.retypePassword || form.newPassword.length < 6) {
    //     message(`"Новый пароль" и "Повторите пароль" должны совпадать!`)
    //     return
    //   }
    //   const data = await request('/api/auth/updatePassword', 'POST', { ...form }, {
    //     authorization: 'Bearer ' + auth.token,
    //     id: auth.user.id
    //   })
    //
    //   message(data.message)
    // } catch (e) {}
  }

  // todo: set -- !auth.user --
  if (auth.user.isAuthenticated) {
    return <LoaderFull/>
  }


  const NoAutocomplete = () => {
    return (
      <>
        <input type="text" style={{display: 'none'}} value=' ' onChange={() => {}}/>
        <input type="password" style={{display: 'none'}} value=' ' onChange={() => {}}/>
      </>
    )
  }




  return (
    <HScreen>
      <div className="container mt-[75px]">
        <div className="flex items-center flex-col gap-14">
        <NoAutocomplete />
          <div className="flex flex-col gap-6 w-[600px]">
            {/*Title*/}
            <span className='text-3xl font-semibold'>Смена пароля</span>

            {/*password*/}
           
            <Input
              className="text-blueGray-300"
              placeholder=" "
              label="Старый пароль"
              type="password"
              id="password"
              name="password"
              autoComplete="nope"
              value={form.password}
              onChange={changeHandler}
            />

            {/*newPassword*/}
            
            <Input
              className="text-blueGray-300"
              placeholder=" "
              label="Новый пароль"
              type="newPassword"
              id="newPassword"
              name="newPassword"
              autoComplete="nope"
              value={form.newPassword}
              onChange={changeHandler}
            />

            {/*retypePassword*/}
           
            <Input
              className="text-blueGray-300"
              placeholder=" "
              label="Повторите новый пароль"
              type="retypePassword"
              id="retypePassword"
              name="retypePassword"
              autoComplete="nope"
              value={form.retypePassword}
              onChange={changeHandler}
            />

            {/*btn*/}
            <Button
              type="primary"
              size="md"
              className="w-full"
              onClick={updateHandler}
              disabled={loading}
            >
              Поменять пароль
            </Button>
          </div>

        </div>
      </div>
    </HScreen>

    // <HScreen>
    //   <div className="container mt-[75px]">
    //     <div className="flex flex-col gap-14">
    //
    //       <div className="flex flex-col gap-6 max-w-1/3">
    //         <div className="flex flex-col gap-1">
    //           <span className="text-sm text-white">Текущий пароль</span>
    //           <Input
    //             className="bg-coolGray-600 text-blueGray-300"
    //             placeholder="Текущий пароль"
    //             type="password"
    //             id="password"
    //             name="password"
    //             value={form.password}
    //             onChange={changeHandler}
    //           />
    //         </div>
    //         <div className="flex flex-col gap-1">
    //           <span className="text-sm text-white">Новый пароль</span>
    //           <Input
    //             className="bg-coolGray-600 text-blueGray-300"
    //             placeholder="Новый пароль"
    //             type="password"
    //             id="newPassword"
    //             name="newPassword"
    //             value={form.newPassword}
    //             onChange={changeHandler}
    //           />
    //         </div>
    //         <div className="flex flex-col gap-1">
    //           <span className="text-sm text-white">Повторите пароль</span>
    //           <Input
    //             className="bg-coolGray-600 text-blueGray-300"
    //             placeholder="Повторите пароль"
    //             type="password"
    //             id="retypePassword"
    //             name="retypePassword"
    //             value={form.retypePassword}
    //             onChange={changeHandler}
    //           />
    //         </div>
    //
    //         <div>
    //           <Button
    //             size="md"
    //             className='min-w-[150px]'
    //             onClick={updateHandler}
    //             disabled={loading}
    //           >
    //             Изменить пароль
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </HScreen>
  )
}
