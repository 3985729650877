
import { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { Input, Modal } from 'antd';
import { useGit } from '../../hooks/git.hook'
import { Button } from '../Button'
import { useSelector } from 'react-redux'
import { useMessage } from '../../hooks/message.hook'

export const NoRepoContent = ({ id, setProjectTree }) => {

  const { createFile, getProjectTreeByProject, getProject, fixAvatar } = useGit()
  const [newFileName, setNewFileName] = useState("")
  const auth = useSelector(state => state.auth)
  const [showModalFile, setShowModalFile] = useState(false)
  const [value, setValue] = useState();
  const message = useMessage()
  const [project, setProject] = useState({})

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const clickHandlerFile = ({ key }) => {
    setShowModalFile(true)
  };


  const clickHandlerReadMe = async () => {
    await createFile(id, auth, 'README.md', "", "main", "Файл")
    message("Готово")
    setProjectTree(await getProjectTreeByProject(id, auth))
  };

  const handleCancel = () => {
    setShowModalFile(false)
  }

  const handleModalFileOk = async () => {
    await createFile(id, auth, newFileName, "", "main", "Файл")
    message("Готово")
    setProjectTree(await getProjectTreeByProject(id, auth))
    setShowModalFile(false)
  }



  useEffect(() => {
    try {
      (async () => {
        setProject(await getProject(id, auth))
        // setValue(project?.http_url_to_repo)
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])


  useEffect(() => {
    try {
      setValue(fixAvatar(project?.http_url_to_repo))
    } catch (e) {
      console.log(e)
    }
  }, [project])



  return (
    <>
      <center>
        <div className="pt-[27px] w-3/4">
          <div className="w-full border-[1px] bg-[#E1F3FF] rounded py-4 px-6 flex flex-col gap-2">
            <p className="font-semibold text-xl flex justify-start">Быстрая установка - если вы делали это раньше</p>
            <div className="flex flex-row items-center ">
              <Radio.Group className="flex flex-row" onChange={onChange} value={value}>
                <Radio value={fixAvatar(project?.http_url_to_repo)}>https</Radio>
                <Radio value={project?.ssh_url_to_repo}>SSH</Radio>
              </Radio.Group>
              <Input placeholder="Basic usage" value={value} />
            </div>
            <p className="font-semibold text-[15px] flex">Для начала работы в также можете создать&nbsp;

              {/* <span className="text-[#0066C3] cursor-pointer" onClick={clickHandlerReadMe}>
                README.md
              </span>,&nbsp; */}


              <span className="text-[#0066C3] cursor-pointer" onClick={clickHandlerFile}>
                новый файл&nbsp;</span>

              или загрузить существующий</p>

          </div>

          <div className="w-full border-[1px] rounded py-4 px-6 flex flex-col gap-2">
            <p className="font-semibold text-base flex justify-start">…or create a new repository on the command line</p>
            <div className="flex flex-col text-[15px] font-normal bg-[#F8FAFC] px-[17px] py-[5px]">
              <p className="flex justify-start">echo "# test" >> README.md</p>
              <p className="flex justify-start">git init</p>
              <p className="flex justify-start">git add README.md</p>
              <p className="flex justify-start">git commit -m "first commit"</p>
              <p className="flex justify-start">git branch -M main</p>
              <p className="flex justify-start">git remote add origin git@github.com:slavik10/test.git</p>
              <p className="flex justify-start">git push -u origin main</p>
            </div>
          </div>

          <div className="w-full border-[1px] rounded py-4 px-6 flex flex-col gap-2">
            <p className="font-semibold text-base flex justify-start">…or push an existing repository from the command line</p>
            <div className="flex flex-col text-[15px] font-normal bg-[#F8FAFC] px-[17px] py-[5px]">
              <p className="flex justify-start">git remote add origin git@github.com:slavik10/test.git</p>
              <p className="flex justify-start">git branch -M main</p>
              <p className="flex justify-start">git push -u origin main</p>
            </div>
          </div>
        </div>

        <div className='w-3/4 flex justify-start pt-4'>

          <Button
            size="md"
            // className="fitt"
            onClick={() => { clickHandlerReadMe() }}
          >
            Создать README.md
          </Button>

        </div>

      </center>




      <Modal
        open={showModalFile}
        title={null}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-5">
          <p className="text-2xl block mb-2">Создать новый файл</p>

          <Input placeholder="File name"
            className=""
            size="large"
            onChange={(e) => {
              setNewFileName(e.target.value)
            }} />

        </div>
        <div className="flex justify-between items-center gap-4 mt-4">
          <Button
            size="md"
            className="w-full"
            onClick={handleModalFileOk}
          >
            Создать
          </Button>
          <Button
            size="md"
            color="secondary"
            className="w-full"
            onClick={() => { setShowModalFile(false) }}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    </>
  )
}