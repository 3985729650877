import React, { useEffect, useState } from 'react'

export const Event = ({ event }) => {


function eventTranslator(action) {
  const allActions = {
    "pushed to": "публикует изменение",
    "pushed new": "инициализирует",
    "created": "создает проект",
    "accepted": "принимает",
    "approved": "одобряет",
    "opened": "открывает",
   }
  return allActions[action]
}


  return (
    
      <div className='flex justify-center items-center mb-4 py-4 px-6 bg-white shadow'>
        <div className='flex flex-col gap-2 w-full'>
          <span className='font-semibold'>{event.author.username} {eventTranslator(event.action_name)} {event?.target_type !== null ? event?.target_type : ""}</span>
          <span className='text-sm'>Сообщение коммита: {event?.push_data?.commit_title}</span>
        </div>
      </div>

  )
}

