import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import { Button } from '../../components/Button'
import LoginLayout from '../../layout/loginLayout'
import Checkbox from '../../components/Checkbox'


export const AuthPage = ({ isLogin = true }) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const message = useMessage()
  const { loading, error, clearError, gitRequest } = useHttp()
  const [form, setForm] = useState({
    email: '',
    password: '',
    password1: '',

  })

  const [confirm, setConfirm] = useState(false)
  // useEffect(() => {
  //   message(error)
  //   clearError()
  // }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const registerHandler = async () => {


    if (form.password !== form.password1) {
      message('Пароли не совпадают')
    }


    if (form.password.length < 8 || form.password1.length < 8) {
      message('Пароль должен быть не менее 8 символов')
    }

    if (!form.email || !form.password || !form.password1 || !confirm) {
      message('Не все поля заполнены')
    } else {
      try {
        let username = form.email.trim().split("@")[0]
        let name = username
        const data = await gitRequest('/api/v4/users', 'POST', { ...form, username, name, skip_confirmation: true }, { authorization: 'Bearer ' + 'glpat-wP_QMbPC8K9uY5RxY4gh' })
        message('Успешно')
        history('/user/login')
        
      } catch (e) {
        console.log(e)
        message('Ошибка при регистрации или слабый пароль')
      }
    }


  }

  const loginHandler = async () => {

    try {
      let username = form.email.trim().split("@")[0]
      
      const data = await gitRequest(`/oauth/token`, 'POST', { grant_type: "password", username: form.email, password: form.password })
      console.log("TOKEN", data)
      if (data.access_token !== null) {
        const allUserData = await gitRequest(`/api/v4/user`, 'GET', null, { authorization: 'Bearer ' + data.access_token })
        // console.log(allUserData)
        dispatch(login({ user: allUserData, token: data.access_token }))
      }

      message('Login')

      message(data.message)
      // обновляем состояние хранилища вызывая функцию логин
      location.state?.from?.pathname ? history(location.state?.from?.pathname) : history('/')
    } catch (e) {
      console.log(e)
      message('Неверный логин или пароль')
    }
  }

  const onChange = (e) => {
    setConfirm(e.target.checked)
  };

  return (
    <LoginLayout>
      <div className="h-[100vh] w-[100vw] flex justify-center items-center">
        <div className="flex flex-col w-[473px] gap-5 px-9 py-8">
          <p className="text-2xl block font-semibold mb-2">{isLogin ? `Войти` : `Регистрация`}</p>

          {/*Form*/}


          <Input
            className="text-blueGray-300 "
            placeholder="Введите свой email"
            id="email"
            name="email"
            value={form.email}
            autoComplete="off"
            onChange={changeHandler}

          />


          <Input
            className="text-blueGray-300"
            placeholder="Введите пароль"
            type="password"
            id="password"
            name="password"
            value={form.password}
            onChange={changeHandler}
            showCount
          />

          {!isLogin ?
            <Input
              className="text-blueGray-300"
              placeholder="Повторите пароль"
              type="password"
              id="password1"
              name="password1"
              value={form.password1}
              onChange={changeHandler}
              showCount
            />
            :
            <></>
          }

          <div className="flex justify-between">
            <Checkbox onChange={onChange}><span className='text-sm text-slate-800 cursor-pointer'>{isLogin ? `Запомнить на 30 дней` : `Регистрируясь вы принимаете условия оферты`}</span></Checkbox>
            {isLogin ?
              <span className='text-sm text-primary-violet cursor-pointer' onClick={() => { history('/user/forget') }}>Забыли пароль?</span>
              :
              <></>
            }
          </div>

          {/*Buttons*/}
          <div className="flex flex-col justify-between items-center gap-4">
            {isLogin ?
              <>
                <Button
                  type="primary"
                  size="md"
                  className="w-full"
                  onClick={loginHandler}
                  disabled={loading}
                >
                  Войти
                </Button>
                <Button
                  size="md"
                  color="secondary"
                  className="w-full"
                  onClick={() => { history('/user/register') }}
                  disabled={loading}
                >
                  Нет аккаунта? Зарегистрируйтесь!
                </Button>
              </>
              :
              <>
                <Button
                  type="primary"
                  size="md"
                  className="w-full"
                  onClick={registerHandler}
                  disabled={loading}
                >
                  Зарегистрироваться
                </Button>
                <Button
                  size="md"
                  color="secondary"
                  className="w-full"
                  onClick={() => { history('/user/login') }}
                  disabled={loading}
                >
                  Уже есть аккаунт? Войдите
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}
