import React, { useEffect, useState } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useMessage } from '../hooks/message.hook'
import { useHttp } from '../hooks/http.hook'
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../components/Button'
import { Input } from 'antd'
import { Member } from '../components/Member/member'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import {
  PlusIcon, DocumentIcon, DocumentDuplicateIcon
} from '@heroicons/react/outline'
import { Divider, Modal } from 'antd'
import { useGit } from '../hooks/git.hook'
import moment from 'moment'
import CodeMirror from '@uiw/react-codemirror'
import { EditorState } from '@codemirror/state'
import { EditorView, Decoration, ViewPlugin } from '@codemirror/view'




export const Search = ({ create = false }) => {
  moment.updateLocale('ru', { week: { dow: 1 } })
  const params = useParams()
  let projectId = params.project
  const [permission, setPermission] = useState("Чтение и запись")
  const [projectName, setProjectName] = useState("")
  const [changePerm, setChangePerm] = useState({})
  const [projectNameFromGit, setProjectNameFromGit] = useState("")
  const message = useMessage()
  const { gitRequest } = useHttp()
  const auth = useSelector(state => state.auth)

  const [searchFilter, setSearchFilter] = useState("projects")

  const history = useNavigate()
  const [search, setSearch] = useState({})
  const req = params.req


  const {
    getSearch
  } = useGit()




  useEffect(() => {

    try {
      (async () => {
        setSearch(await getSearch(params.req, auth))
      })()
    } catch (e) {
      console.log(e)
    }
  }, [req])




  const redirectToProject = (req) => {
    history(`/user/project/${req}/byproject`)
  }


  const redirectToMerge = (req) => {
    // history(`/user/project/${req}/byproject`)
    history(`/user/repo/8/pr_Renamed/merj/main/secondary`)
  }





  const SearchProject = ({ res, path, id, type, created_at, description }) => {
    return (
      <>

        <div className='flex justify-center items-center mb-4 py-4 px-6 bg-white shadow'>
          <div className='flex flex-col gap-2 w-full'>
            <span className='font-semibold'><p className='text-[#0066C3] hover cursor-pointer hover:text-[#000]' onClick={() => { type === "project" ? redirectToProject(id) : redirectToMerge(id) }}>{res}</p></span>
            <span className='text-sm'><p>Описание: {description}</p></span>
            <span className='text-sm'><p>Дата создания: {moment(created_at).format('D MMM YYYY')}</p></span>
          </div>
        </div>

      </>
    )
  }




  const SearchMerge = ({ res, path, id, type, created_at, description, title, author }) => {
    return (
      <>

        <div className='flex justify-center items-center mb-4 py-4 px-6 bg-white shadow'>
          <div className='flex flex-col gap-2 w-full'>
            <span className='font-semibold'><p className='text-[#0066C3] hover cursor-pointer hover:text-[#000]' onClick={() => { type === "project" ? redirectToProject(id) : redirectToMerge(id) }}>{res}</p></span>
            <span className='text-sm'><p>Заголовок: {title}</p></span>
            <span className='text-sm'><p>Описание: {description}</p></span>

            <span className='text-sm'><p>Дата создания: {moment(created_at).format('D MMM YYYY')}</p></span>
          </div>
        </div>

      </>
    )
  }

  const SearchCode = ({ id, projectName, basename, data, filename, path }) => {
    const [highlightLine, setHighlightLine] = useState(null)

    // Определяем строку с "test" и получаем индекс строки
    useEffect(() => {
      const lines = data.split('\n')
      const lineIndex = lines.findIndex(line => line.includes(req))
      setHighlightLine(lineIndex + 1) // CodeMirror использует индексы с 1
    }, [data])

    // Плагин для подсветки строки
    const highlightLinePlugin = ViewPlugin.fromClass(
      class {
        constructor(view) {
          this.view = view
        }

        update(update) {
          if (update.docChanged || update.viewportChanged) {
            this.decorations = this.getDecorations()
          }
        }

        getDecorations() {
          if (!highlightLine) return Decoration.none
          const line = this.view.state.doc.line(highlightLine)
          const highlightDeco = Decoration.line({ class: 'highlight-line' })
          return Decoration.set([highlightDeco.range(line.from)])
        }

        decorations() {
          return this.getDecorations()
        }
      },
      { decorations: v => v.decorations() }
    )

    return (
      <>
        <div className='flex flex-col border-[1px] border-[#D9D9D9] px-[8px] py-[4px] mb-[20px] rounded-l gap-[4px]'>
          <div className='flex flex-row gap-1  ml-[5px]'><div>Репозиторий:</div>  <div className='font-semibold'>{projectName}</div></div>
          <div className='flex flex-row gap-[6px] items-center'>
            <DocumentIcon className='w-5 h-5' />
            <div className='text-[#0066C3] hover cursor-pointer hover:text-[#000]' onClick={() => history(`/user/repo/${id}/${projectName}/content`)}>{filename}</div>
            <DocumentDuplicateIcon className='w-5 h-5 cursor-pointer hover:text-[#ccc]' onClick={() => navigator.clipboard.writeText(filename)} />
          </div>
          <CodeMirror
            value={data}
            readOnly={true}
            style={{ width: '100%', borderRadius: '50px' }}
            extensions={[highlightLinePlugin]}
          />
        </div>
      </>
    )
  }




  useEffect(() => {
    try {
      (async () => {
        setSearch(await getSearch(params.req, auth))
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])


  const projectList = search?.projects?.map((res, idx) =>
    < li key={idx}>
      <SearchProject
        res={res.value}
        path={res.path}
        id={res.id}
        type="project"
        created_at={res.created_at}
        description={res.description}
      />
    </li>
  )


  const mergeList = search?.merges?.map((res, idx) =>
    < li key={idx}>
      <SearchMerge
        res={res.value}
        path={res.path}
        id={res.project_id}
        target={res.target_branch}
        source={res.source_branch}
        description={res.description}
        title={res.title}
        type="merge"
      />
    </li>
  )


  const codeList = search?.codeSearch?.map((res, idx) =>
    < li key={idx}>
      <SearchCode
        id={res.id}
        projectName={res.projectName}
        basename={res.basename}
        data={res.data}
        filename={res.filename}
        path={res.path}
      />
    </li>
  )









  return (
    <HScreen
      project={{
        name: 'some 2Project',
      }}
    >

      <div className='flex flex-row gap-[20px] w-full'>

        <div className='flex flex-col w-1/5 pl-[50px] pt-[50px] gap-[8px]'>

          <div className={`px-[8px] py-[6px] hover:bg-slate-100 ${searchFilter === "projects" ? "bg-slate-100 " : "bg-white"} rounded-lg cursor-pointer flex flex-row justify-between items-center`} onClick={() => { setSearchFilter("projects") }}>
            <div>Репозитории</div>
            <div className={` ${searchFilter === "projects" ? "bg-[#633EBA] text-[#fff]" : "bg-slate-100 text-[#000]"} rounded-2xl px-[16px] py-[4px]`}>{projectList?.length}</div>
          </div>
          <div className={`px-[8px] py-[6px] hover:bg-slate-100 ${searchFilter === "code" ? "bg-slate-100 " : "bg-white"} rounded-lg cursor-pointer  flex flex-row justify-between items-center`} onClick={() => { setSearchFilter("code") }}>
            <div>Код</div>
            <div className={` ${searchFilter === "code" ? "bg-[#633EBA] text-[#fff]" : "bg-slate-100 text-[#000]"} rounded-2xl px-[16px] py-[4px]`}>{codeList?.length}</div>
          </div>
          <div className={`px-[8px] py-[6px] hover:bg-slate-100 ${searchFilter === "merge" ? "bg-slate-100 " : "bg-white"} rounded-lg cursor-pointer  flex flex-row justify-between items-center`} onClick={() => { setSearchFilter("merge") }}>
            <div>Запросы на слияние</div>
            <div className={` ${searchFilter === "merge" ? "bg-[#633EBA] text-[#fff]" : "bg-slate-100 text-[#000]"} rounded-2xl px-[16px] py-[4px]`}>{mergeList?.length}</div>
          </div>

        </div>



        <div className='flex flex-col w-full'>
          <div className='flex justify-center bg-[#F8FAFC]' >

            <p className='text-[24px] font-semibold'>Результаты поиска по запросу: <span className='text-[#0066C3]'>{req}</span></p>

          </div>
          <div className='flex justify-center bg-[#F8FAFC]   h-[calc(100vh-80px)] w-full overflow-auto scrollbar-hide md:scrollbar-default '>
            <div className='w-2/3 flex flex-col  '  >


              {searchFilter === "projects" ?
                <>
                  {projectList?.length ?
                    <>

                      <div className='text-[20px] font-semibold my-[20px]'>Показано {projectList?.length} включений по проектам</div>
                      <ul>{projectList}</ul>
                    </>
                    :
                    <div className='text-[20px] font-semibold my-[20px]'>Ничего не найдено</div>
                  }
                </>
                :
                <></>
              }



              {searchFilter === "merge" ?
                <>
                  {mergeList?.length ?
                    <>
                      <div className='text-[20px] font-semibold my-[20px]'>Показано {mergeList?.length} включений по запросам на слияние</div>
                      <ul>{mergeList}</ul>
                    </>
                    :
                    <div className='text-[20px] font-semibold my-[20px]'>Ничего не найдено</div>
                  }
                </>
                :
                <></>
              }


              {searchFilter === "code" ?
                <>
                  {codeList?.length ?
                    <>

                      <div className='text-[20px] font-semibold my-[20px]'>Показано {codeList?.length} включений по коду</div>
                      <ul>{codeList}</ul>
                    </>
                    :
                    <div className='text-[20px] font-semibold my-[20px]'>Ничего не найдено</div>
                  }
                </>
                :
                <></>
              }








            </div>
          </div>
        </div>
      </div>




    </HScreen>
  )
}