import { ConfigProvider, Input as AntdInput } from 'antd'
import React from 'react'

const { TextArea: AntTextArea } = AntdInput

export const TextArea = ({ ...props }) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#633EBA',
            // colorPrimaryHover: '#5435a1',
          },
        }}
      >
        <AntTextArea
          {...props}
        />
      </ConfigProvider>
    </>
  )
}