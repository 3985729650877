import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../redux/actions/auth.actions'
import HScreen from '../../layout/hScreen'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import Input from '../../components/Input'
import { Button } from '../../components/Button'


export const UserProfilePage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError, gitRequest } = useHttp()
  const [form, setForm] = useState({
    id: '',
    email: '',
    name: '',
    img: '/assets/userImg/no.png'
  })
  const [show, setShow] = useState(false)
  const message = useMessage()
  const history = useNavigate()
  const inputFile = useRef(null)
  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  useEffect(() => {
    try {
      // todo: REMOVE else branch, it's only for test
      if (auth.isAuthenticated) {
        setForm({
          id: auth.user.id,
          email: auth.user.email,
          name: auth.user.username,
          img: auth.user.avatar_url ? `${auth.user.photo}?v=${Date.now()}` : `/assets/userImg/no.png`
        })
      } else {
        setForm({
          id: 1,
          email: 'test@test.ru',
          name: 'Alex',
          img: `/assets/userImg/no.png`
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [auth])




  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    message('Update profile')
    try {
      const user = { ...auth.user, ...form }
      const data = await gitRequest(`/api/v4/users/${form.id}`, 'PUT', { id: form.id, username: form.name, email: form.email}, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })

      dispatch(login({ user: data, token: auth.token }))
      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }

  const imgChangeHandler = async event => {
    message('Change img')
    // try {
    //   const fd = new FormData();
    //   fd.append('avatar', event.target.files[0])
    
    //   const data = await fileUpload('/api/auth/upload', 'POST', fd, {authorization: 'Bearer ' + auth.token, id: auth.user.id})
    
    //   setForm({ ...form, img: `/assets/userImg/${auth.user.id}.jpg?v=${Date.now()}` })
    //   dispatch(login({token: auth.token, user: { ...auth.user, photo: `/assets/userImg/${auth.user.id}.jpg`}}))
    
    //   message(data.message)
    // } catch (e) {
    //   console.log(e)
    // }
  }

  // todo: set -- !auth.user --
  if (auth.user.isAuthenticated) {
    return <LoaderFull />
  }

  return (
    <HScreen>
      <div className="container mt-[75px]">
        <div className="flex items-center flex-col gap-14">

          <div className="flex flex-col gap-6 w-[600px] justify-start ">
            {/*Title*/}
            <span className='text-3xl font-semibold'>Данные профиля</span>

            {/*photo*/}
            <div className='flex flex-col gap-2'>
              <span className='text-sm text-slate-400 items-start'>Фото профиля</span>
              <img
                style={{
                  borderRadius: '50%',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  width: '75px',
                  height: '75px',
                  border: '1px solid grey'
                }}
                // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                src={"https://git.app.ontory.ru" + auth.user.avatar_url.slice(25)}
                alt=""
                onClick={() => {
                  inputFile.current.click()
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = auth.user.avatar_url;
                }}
              />

              <input
                type="file"
                id="avatar"
                ref={inputFile}
                style={{ display: 'none' }}
                // value={form.img}
                onChange={imgChangeHandler}
                accept=".jpg"
              />

            </div>
            

            {/*name*/}
            <Input
              className="text-blueGray-300"
              placeholder=" "
              label="Ваше имя"
              id="name"
              name="name"
              value={form.name}
              autoComplete="off"
              onChange={changeHandler}
            />

            {/*email*/}
            <Input
              className="text-blueGray-300"
              placeholder=" "
              label="Ваш email"
              id="email"
              name="email"
              value={form.email}
              autoComplete="off"
              onChange={changeHandler}
            />

            {/*btn*/}
            <Button
              type="primary"
              size="md"
              className="w-full"
              onClick={updateHandler}
              disabled={loading}
            >
              Сохранить
            </Button>
          </div>

        </div>
      </div>
    </HScreen>
  )
}
