import { AUTH_LOGIN, AUTH_LOGOUT } from '../types/types'

const storageName = 'userDataOntory'
/**
 *
 * @param data (token, user)
 * @returns {{payload, type: string}}
 */

export const login = (data) => {
 localStorage.setItem(storageName, JSON.stringify(data))
 console.log("localStorage", localStorage)
 return {
  type: AUTH_LOGIN,
  payload: data
 }
}

export const logout = () => {
 localStorage.removeItem(storageName)
 return {
  type: AUTH_LOGOUT
 }
}

export const checkLogged = () => {
 return async dispatch => {
  const data = JSON.parse(localStorage.getItem(storageName))

  if (data && data.token && data.user) {
   let body = JSON.stringify({ userId: data.user.id })
   let headers = {}
   headers['Content-Type'] = 'application/json'
   headers.authorization = 'Bearer ' + data.token
   const response = await fetch(`https://git.app.ontory.ru/api/v4/users/${data.user.id}`, { method: 'GET', headers })
   const res = await response.json()
//    console.log("USER:-", res)

   if (res.message !== '404 User Not Found' && res?.isUser !== false) {
    dispatch(login(data))
    return 'auth'
   } else {
    dispatch(logout())
    return 'not auth'
   }
  }

  return 'not auth'
 }
}