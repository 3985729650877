
import { LineMenu } from '../../components/LineMenu'
import { Event } from '../../components/Event/event.js'
import { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook'
import "./index.css"
import { ContribCalendar } from '../ContribCalendar/contribCalendar'
import { useGit } from '../../hooks/git.hook'
import { useSelector } from 'react-redux'


export const ByProject = ({ id }) => {
  const { getContributions } = useGit()
  const auth = useSelector(state => state.auth)
  const [projectEvent, setProjectEvent] = useState([])
  const listOfEvents = projectEvent.map((event, idx) =>
    <li key={idx}><Event event={event} /></li>
  );
  const [contrib, setContrib] = useState({})
  const { gitRequest } = useHttp()
  const [contribData, setContribData] = useState(null)


  useEffect(() => {
    try {
      (async () => {
        const events = await gitRequest(`/api/v4/projects/${id}/events`, 'GET', null, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })
        setProjectEvent(events)
      })();
      (async () => {
        const res = await fetch(
          'https://raw.githubusercontent.com/scriptex/atanas.info/master/src/data/gitlab-calendar.json'
        );
        const data = await res.json();
        setContribData(data)
        setContrib(await getContributions(id, auth))
      })();
    } catch (e) {
      console.log(e)
    }
  }, [id])


  //   useEffect(() => {
  // console.log("data-", contribData)
  //     if (contribData) {

  //       let $contr = document.getElementById('contributions')
  //       $contr.innerHTML = ""
  //       new GitlabCalendar($contr, contribData, {
  //         daySize: 15,
  //         hintText: "pushes",
  //       });
  //     }
  //   }, [contribData]) // на получение даты



  return (
    <div >
      {/* <div id="contributions" /> */}
      {/* <GithubContributions username="valdisl0ve" color="#32CD32"
        howTooltips={false}
        isResponsive={false}
        showTotal={false}
        showToday={false}
        showTitle={false}
        showIcon={false}
      /> */}

      <ContribCalendar commits={contrib} options={{ daySpace: 1 }} />
      <div className="flex flex-col gap-4">
        <LineMenu items={
          [
            { id: 0, text: 'Лента активности' },
          ]
        } />
        <div className=' h-[calc(100vh-400px)] w-full overflow-auto scrollbar-hide md:scrollbar-default'>
          <div className='flex justify-center items-center mb-4 py-4 px-6 bg-white shadow'>
            <div className='flex flex-col gap-2 w-full'>
              <span className='font-semibold'>Добро пожаловать в вашу ленту новостей</span>
              <span className='text-sm'>Здесь будут отображаться все последние новости, которые происходят с проектом: создание новых репозиториев, релизов, мердж реквесторв или production билдов</span>
            </div>
          </div>
          <ul>{listOfEvents}</ul>
        </div>
      </div>
    </div>
  )
}