import { message } from 'antd'

import { useHttp } from './http.hook'
export const useGit = () => {
    const { gitRequest, gitTextRequest } = useHttp()

    const fixAvatar = (url) => {
        return "https://git.app.ontory.ru/" + url?.substring(26, url.length)
    }



    const sendMergeRequest = async (id, source_branch, target_branch, title, description, auth) => {

        try {
            const send = await gitRequest(`/api/v4/projects/${id}/merge_requests`, 'POST', { id, source_branch, target_branch, title, description }, { authorization: 'Bearer ' + auth.token })
            return "Мердж реквест создан"
        } catch (error) {

            return "Another open merge request already exists for this source branch"

        }

    }

    const getRepoBranches = async (id, auth) => {
        try {
            const get = await gitRequest(`/api/v4/projects/${id}/repository/branches`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            let branches = get.map(branch => {
                return {
                    label: branch.name,
                    key: branch.name,
                }
            })
            return branches
        } catch (error) {
            console.log("getRepoBranches error: ", error)
            return []
        }
    }

    const getMergeRequest = async (id, auth) => {
        try {
            const getMergeRequests = await gitRequest(`/api/v4/projects/${id}/merge_requests`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            return getMergeRequests
        } catch (error) {
            console.log("getMergeRequest error: ", error)
            return []
        }
    }

    const getDiffBetweenBranches = async (id, sourceBranch, targetBranch, auth) => {

        try {
            const getDiff = await gitRequest(`/api/v4/projects/${id}/repository/compare?from=${sourceBranch}&to=${targetBranch}`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            let diffString = "Index: NAME\n=================="
            for (let i = 0; i < getDiff.diffs.length; i++) {
                diffString = diffString + `\n--- ${getDiff.diffs[i].new_path}\n+++ ${getDiff.diffs[i].old_path}\n${getDiff.diffs[i].diff}`

            }
            return diffString
        } catch (error) {
            console.log("getDiffBetweenBranches error: ", error)
            return {}
        }
    }

    const getReleases = async (id, auth) => {

        try {
            const getReleases = await gitRequest(`/api/v4/projects/${id}/releases`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return getReleases
        } catch (error) {
            console.log("getReleases error: ", error)
            return {}
        }
    }

    const getProjectTreeByProject = async (id, auth) => {

        try {
            const getProjectTree = await gitRequest(`/api/v4/projects/${id}/repository/tree`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return getProjectTree
        } catch (error) {
            console.log("getProjectTreeByProject error: ", error)
            return {}
        }
    }

    const getProjectTreeByBranch = async (id, auth, branch, path) => {
        try {
            const getProjectTree = await gitRequest(`/api/v4/projects/${id}/repository/tree?path=${path.replaceAll('/', '%2F')}&ref=${branch}`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return getProjectTree
        } catch (error) {
            console.log("getProjectTreeByBranch error: ", error)
            return {}
        }
    }

    const getCode = async (id, auth, path, branch) => {
        try {
            const getCode = await gitTextRequest(`/api/v4/projects/${id}/repository/files/${path.replaceAll('/', '%2F')}/raw?ref=${branch}`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return getCode
        } catch (error) {
            console.log("getCode error: ", error)
            return {}
        }
    }

    const updateFile = async (id, auth, path, branch, commit, content) => {

        try {
            const updFile = await gitRequest(`/api/v4/projects/${id}/repository/files/${encodeURIComponent(path)}`, 'PUT', { branch: branch, commit_message: commit, content: content }, { authorization: 'Bearer ' + auth.token })

            return "Файл обновлён"
        } catch (error) {
            console.log("updateFile error: ", error)
            return "Ошибка обновления файла"
        }
    }

    const deleteFile = async (id, auth, path, branch, commit) => {

        try {
            const delFile = await gitRequest(`/api/v4/projects/${id}/repository/files/${encodeURIComponent(path)}`, 'DELETE', { branch: branch, commit_message: commit }, { authorization: 'Bearer ' + auth.token })

            return "Файл удалён"
        } catch (error) {
            console.log("deleteFile error: ", error)
            return "Ошибка удаления файла"
        }
    }

    const getCommits = async (id, auth) => {

        try {
            const commits = await gitRequest(`/api/v4/projects/${id}/repository/commits`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return commits
        } catch (error) {
            console.log("updateFile error: ", error)
            return {}
        }
    }

    const getTags = async (id, auth) => {

        try {
            const tags = await gitRequest(`/api/v4/projects/${id}/repository/tags`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            return tags
        } catch (error) {
            console.log("getTags error: ", error)
            return {}
        }
    }

    const createTag = async (id, auth, branch, name) => {

        try {
            const tags = await gitRequest(`/api/v4/projects/${id}/repository/tags?ref=${branch}`, 'POST', { tag_name: name }, { authorization: 'Bearer ' + auth.token })
            return "Тэг создан"
        } catch (error) {
            console.log("getTags error: ", error)
            return "Ошибка создания тэга"
        }
    }

    const createRelease = async (id, auth, ref, title, description, tag_name) => {

        try {
            const release = await gitRequest(`/api/v4/projects/${id}/releases`, 'POST', { tag_name, title, ref, description }, { authorization: 'Bearer ' + auth.token })
            return "Релиз создан"
        } catch (error) {
            console.log("getTags error: ", error)
            return "Ошибка создания релиза"
        }
    }

    const createBranch = async (id, auth, ref, name) => {

        try {
            const branch = await gitRequest(`/api/v4/projects/${id}/repository/branches`, 'POST', { branch: name, ref }, { authorization: 'Bearer ' + auth.token })
            return "Ветка создана"
        } catch (error) {
            console.log("createBranch error: ", error)
            return "Ошибка создания ветки"
        }
    }


    const createFile = async (id, auth, name, path, branch, type) => {

        try {
            if (type == "Файл") {

                const file = await gitRequest(`/api/v4/projects/${id}/repository/files/${encodeURIComponent(path)}${!path ? "/" : "%2F"}${name}`, 'POST', { branch, commit_message: "New file", content: "" }, { authorization: 'Bearer ' + auth.token })
                return "Файл создан"
            } else {
                const folder = await gitRequest(`/api/v4/projects/${id}/repository/files/${encodeURIComponent(path)}${!path ? "/" : "%2F"}${name}%2F.gitkeep`, 'POST', { branch, commit_message: "New folder", content: "" }, { authorization: 'Bearer ' + auth.token })
                return "Папка создана"
            }

        } catch (error) {
            console.log("createFile error: ", error)
            return "Ошибка создания файла или папки"
        }
    }


    const getSingleProject = async (id, auth) => {

        try {
            const project = await gitRequest(`/api/v4/projects/${id}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            return project
        } catch (error) {
            console.log("getTags error: ", error)
            return {}
        }
    }

    const createEditJiraIntgration = async (id, auth, url, username, password) => {

        try {

            const integration = await gitRequest(`/api/v4/projects/${id}/integrations/jira`, 'PUT', { url, username, password, active: true, commit_events: true, merge_requests_events: true, comment_on_event_enabled: true }, { authorization: 'Bearer ' + auth.token })

            return "JIRA integrated"
        } catch (error) {
            console.log("createEditJiraIntgration error: ", error)
            return "JIRA error"
        }
    }












    const getContributions = async (id, auth) => {

        try {
            const events = await gitRequest(`/api/v4/projects/${id}/repository/commits?per_page=3000`, 'GET', null, { authorization: 'Bearer ' + auth.token })

            let arr = []
            let result = events.map(event => {
                arr.push(event.authored_date.substring(0, 10))
            })
            const res = arr.reduce((acc, i) => {
                if (acc.hasOwnProperty(i)) {
                    acc[i] += 1
                } else {
                    acc[i] = 1
                }
                return acc
            }, {})
            return res

        } catch (error) {
            console.log("getContributions error: ", error)
            return {}
        }
    }

    const fetchAllProjects = async (auth) => {
        let projects = []
        let page = 1
        const perPage = 100

        while (true) {
            const url = `https://git.app.ontory.ru/api/v4/projects?per_page=${perPage}&page=${page}`

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + auth.token
                    }
                })

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }

                const data = await response.json()
                if (data.length === 0) break

                projects = projects.concat(data)
                page++
            } catch (error) {
                console.error("Error fetching projects:", error)
                break
            }
        }

        return projects
    }

    const searchCodeInProject = async (searchTerm, projectId, auth) => {
        const url = `https://git.app.ontory.ru/api/v4/projects/${encodeURIComponent(projectId)}/search?scope=blobs&search=${encodeURIComponent(searchTerm)}`

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + auth.token
                }
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }

            return await response.json()
        } catch (error) {
            console.error("Error searching code:", error)
            return []
        }
    }

    const getSearch = async (req, auth, id) => {
        try {
            // Поиск проектов
            const byProjects = await gitRequest(`/api/v4/search?scope=projects&search=${req}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            let projects = byProjects.map(el => ({
                value: el.name,
                path: el.path,
                id: el.id,
                description: el.description,
                created_at: el.created_at
            }))

            // Поиск merge requests
            const byMerge = await gitRequest(`/api/v4/search?scope=merge_requests&search=${req}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            let merges = byMerge.map(el => ({
                value: el.title,
                path: el.path,
                projectId: el.project_id,
                target: el.target_branch,
                source: el.source_branch,
                title: el.title,
                description: el.description,
            }))

            // Получение всех проектов
            let allProjects = await fetchAllProjects(auth)

            // Поиск по коду во всех проектах
            let searchResults = []
            for (const project of allProjects) {
                const results = await searchCodeInProject(req, project.id, auth)
                searchResults.push({
                    projectId: project.id,
                    projectName: project.name,
                    results: results
                })
            }



            let mapSearchResults = searchResults.flatMap(project =>
                project.results.map(result => ({
                    id: project.projectId,
                    projectName: project.projectName,
                    basename: result.basename,
                    data: result.data,
                    filename: result.filename,
                    path: result.path
                }))
            )


            let resulting = {
                projects: projects,
                merges: merges,
                codeSearch: mapSearchResults
            }

            // console.log("resulting----", resulting)

            return resulting
        } catch (error) {
            console.error("getSearch error: ", error)
            return []
        }
    }

    // Пример использования
    // const searchTerm = 'main';
    // const auth = { token: 'your_access_token' };

    // const executeSearch = async () => {
    //     try {
    //         const results = await getSearch(searchTerm, auth);
    //         console.log("Search results:", results);
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };

    // executeSearch();




    const getProject = async (id, auth) => {
        try {
            const projectInfo = await gitRequest(`/api/v4//projects/${id}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
            return projectInfo
        } catch (error) {
            console.log("getProject error: ", error)
            return []
        }
    }









    const mergeAccept = async (id, mergeReqId, merge_commit_message, auth) => {
        try {
            const send = await gitRequest(`/api/v4/projects/${id}/merge_requests/${mergeReqId}/merge`, 'PUT', { id, mergeReqId, merge_commit_message, should_remove_source_branch: false, auth }, { authorization: 'Bearer ' + auth.token })
            return "Успешное слияние"
        } catch (error) {
            return "Ошибка слияния"
        }
    }


    const mergeDecline = async (id, mergeReqId, auth) => {
        try {
            const send = await gitRequest(`/api/v4/projects/${id}/merge_requests/${mergeReqId}`, 'DELETE', {}, { authorization: 'Bearer ' + auth.token })
            // console.log("send", send)
            return "Запрос на слияние удалён"
        } catch (error) {
            console.log("error", error)
            return "Запрос на слияние удалён"
        }
    }






    return {
        fixAvatar, sendMergeRequest, getRepoBranches, getMergeRequest,
        getDiffBetweenBranches, getReleases, getProjectTreeByBranch,
        getProjectTreeByProject, getCode, updateFile, getCommits, deleteFile, getTags, createTag, createRelease,
        createBranch, createFile, getSingleProject, createEditJiraIntgration, getContributions, getSearch, getProject, mergeAccept, mergeDecline
    }
}