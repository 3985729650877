import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../redux/actions/auth.actions'
import HScreen from '../../layout/hScreen'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import { Button } from '../../components/Button'
import { Divider, Modal } from 'antd'
import { PlusIcon, KeyIcon, XIcon } from '@heroicons/react/outline'
import moment from 'moment'
import 'moment/locale/ru'
import Input from '../../components/Input'
import { TextArea } from '../../components/TextArea'


export const UserKeysPage = () => {
  moment.updateLocale('ru', { week: { dow: 1 } })
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError, gitRequest } = useHttp()
  const [someChanges, setSomeChanges] = useState(0)
  const [form, setForm] = useState({
    key: '',
    title: '',
    expires_at: "2023-01-21T00:00:00.000Z",
  })
  const [show, setShow] = useState(false)
  const message = useMessage()
  const history = useNavigate()
  const [keys, setKeys] = useState([])

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    try {
      (async () => {
        const getKeys = await gitRequest(`/api/v4/user/keys`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setKeys(getKeys)
      })()
    } catch (error) {
    }
  }, [])



  useEffect(() => {
    try {
      (async () => {
        const getKeys = await gitRequest(`/api/v4/user/keys`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setKeys(getKeys)
      })()
    } catch (error) {
    }
  }, [someChanges])





  // todo: add key

  const addKeyHandler = async () => {
    try {
      const addKey = await gitRequest(`/api/v4/user/keys`, 'POST', { ...form }, { authorization: 'Bearer ' + auth.token })
      message('Add SSH Key')
      setSomeChanges(someChanges + 1)
    } catch (error) {
      message('Error SSH Key')
    }
   
  }

  async function delKey(keyId) {
    try {
      const delKey = await gitRequest(`/api/v4/user/keys/${keyId}`, 'DELETE', null, { authorization: 'Bearer ' + auth.token })
    } catch (error) {
      console.log(error)
    }
  }

  // MODAL START
  const showModal = () => {
    setShow(true)
  }

  const handleCancel = () => {
    setForm({
      key: '',
      title: '',
      expires_at: moment().format('YYYY-MM-DD'),
    })
    setShow(false)
  }

  const handleOk = async e => {
    await addKeyHandler()
    handleCancel()
  }
  // MODAL END

  const ShowKey = (el) => {
    return (
      <div className="flex justify-between items-center w-full" key={el.key}>
        {/*key icon*/}
        <KeyIcon className="w-[40px] h-[40px] text-gray-700" />
        {/*key body*/}
        <div className="flex flex-col flex-1 px-4">
          <span className="font-semibold leading-6">{el.title}</span>
          <span className="text-slate-300 leading-6">{el.key.substr(0, 50) + "..."}</span>
          <span className="text-slate-300 leading-6">Добавлен {moment(el.expires_at).format('D MMM YYYY')}</span>
        </div>
        {/*close icon*/}
        <XIcon className="w-[24px] h-[24px] text-slate-300 cursor-pointer hover:text-slate-400" onClick={
          () => {
            setKeys(keys.filter(key => key.key !== el.key))
            delKey(el.id)
          }} />
      </div>
    )
  }

  if (!auth.user) {
    return <LoaderFull />
  }

  return (
    <HScreen>
      <div className="container mt-[75px]">
        <div className="flex items-center flex-col gap-14">

          <div className="flex flex-col gap-4 w-[800px] mt-[50px]">
            {/*Title*/}
            <div className="flex gap-4 justify-between w-full items-center">
              <span className="text-3xl font-semibold">SSH Ключи</span>

              {/*btn*/}
              <Button
                color="secondary"
                size="slim"
                className="w-1/3"
                onClick={showModal}
                disabled={loading}
              >
                Добавить SSH ключ
              </Button>
            </div>

            <span className="text-sm text-slate-300">Ниже представлен список ssh ключей привязанных к вашему аккаунту, если в списке вы нашли ключ, который не принадлежит вам - удалите его</span>

            <Divider className="my-4" />

            {/*list of keys*/}
            {keys.length ?
              <div className="flex flex-col gap-4 w-full mb-2">
                {keys.map(ShowKey)}
              </div>
              :
              <span className="w-full text-center text-slate-400 mb-2">Пока не добавлено ни одного SSH ключа</span>
            }

            {/*add btn*/}
            <div
              className="flex gap-2 text-slate-300 cursor-pointer items-center hover:text-slate-400"
              onClick={showModal}
            >
              <PlusIcon className="w-5 h-5" />
              <span>Добавить SSH ключ</span>
            </div>
          </div>

        </div>
      </div>

      <Modal
        open={show}
        title={null}
        footer={null}
        // centered
        width={640}
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-4">
          {/*title*/}
          <div className='flex flex-col gap-2'>
            <p className="text-2xl block">Добавление SSH ключа</p>
            <Divider className="my-2" />
          </div>

          {/*body*/}
          <div className='flex flex-col gap-4'>
            <Input
              className="text-blueGray-300"
              placeholder="Введите название ключа"
              id="title"
              name="title"

              autoComplete="off"
              onChange={changeHandler}
            />

            <TextArea
              rows={4}
              placeholder="SSH ключ. Begins with 'ssh-rsa'"
              id="key"
              name="key"
              onChange={changeHandler}
            />
          </div>

          <div className="flex justify-between items-center gap-4">
            <Button
              size="slim"
              className="w-1/3 mt-2"
              onClick={handleOk}
            >
              Добавить
            </Button>
            <Button
              size="slim"
              color="secondary"
              className="w-full hidden"
              onClick={handleCancel}
            >
              Отмена
            </Button>
          </div>
        </div>
      </Modal>

    </HScreen>
  )
}
