import { useState, useEffect } from 'react';
import { LineMenu } from '../LineMenu'
import { useParams, useNavigate } from 'react-router-dom'





export const Test = ({ }) => {
  const history = useNavigate()
  const params = useParams()
  const id = params.id
  const type = params.typeparam
  const [curr, setCurr] = useState(null)


  const Content = () => {
    const fileClickHandler = (e) => {
      setCurr("file")
    }
    return (

      <div>
        <button onClick={() => setCurr(null)}>Вернуться к дереву файлов</button>
        {
          curr ? <p>{curr}</p> : <p onClick={fileClickHandler}>Дерево файлов</p>
        }
      </div>
    )
  }

  const Switcher = () => {
    switch (type) {
      case "content":
        return <Content /> // component
      case "merj":
        return (<p>merj</p>) // component
      case "reliz":
        return (<p>reliz</p>) // component
      case "teg":
        return (<p>teg</p>) // component
      case "settings":
        return (<p>settings</p>) // component
    }
  }

  return (
    <>

      <LineMenu items={
        [
          { id: 0, text: 'Код', href: `/user/test/${8}/content` },
          { id: 1, text: 'Мердж Реквесты', href: `/user/test/${8}/merj` },
          { id: 2, text: 'Релизы', href: `/user/test/${8}/reliz` },
          { id: 3, text: 'Настройки', href: `/user/test/${8}/teg` },
          { id: 3, text: 'Настройки', href: `/user/test/${8}/settings` },
        ]
      } />


      <Switcher />


    </>
  )
}