import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import {
  XIcon,
  LightningBoltIcon
} from '@heroicons/react/outline'


export const Member = ({ id, name, email, avatar, permissions, setProjectMember, projectMember, setChangePerm, setToDel, create }) => {

  const auth = useSelector(state => state.auth)
  const [permission, setPermission] = useState(permissions)
  const onClick = ({ key }) => {
    setPermission(key)
    setChangePerm({ email: email, permission: key })
  };
  const items = [
    {
      label: 'Администратор',
      key: 'Администратор',
    },
    {
      type: 'divider',
    },
    {
      label: 'Чтение и запись',
      key: 'Чтение и запись',
    },
    {
      label: 'Только чтение',
      key: 'Только чтение',
    },
  ];

  return (
    <div className='flex flex-row items-center text-[#9CA3AF] mt-4'>
      <div>
        {name === auth.user.name
          ?
          <img
            style={{
              borderRadius: '50%',
              overflow: 'hidden',
              cursor: 'pointer',
              width: '40px',
              height: '40px',
              border: '1px solid grey'
            }}
            // todo check if exists`/assets/userImg/${props.user.id}.jpg`
            src={avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://git.app.ontory.ru" + avatar.slice(25);
            }}

          />
          :
          <div>
            {
              create ?
                <LightningBoltIcon className='text-[#000] w-[40px]' />
                :
                <img
                  style={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    border: '1px solid grey'
                  }}
                  // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                  src={avatar}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://git.app.ontory.ru" + avatar.slice(25);
                  }}
                />
            }

          </div>
        }
      </div>

      <div className='flex flex-row justify-between w-full pl-4'>
        <div className='flex flex-col'>
          {name === auth.user.name ? <div className='text-[#000] text-base font-semibold' > {name + ' (Вы)'} </div> : <></>}
          {name !== auth.user.name ? <div className='text-base text-[#000] font-semibold'>{email}</div> : <div className='text-base font-normal'>{email}</div>}
          {name !== auth.user.name ? <div>отправлено приглашение</div> : <></>}
        </div>

        <div className='flex flex-row items-center'>
          <Dropdown
            menu={{
              items,
              onClick,
            }}
            trigger={['click']} className="mb-[7px]  cursor-pointer hover:text-[#000]" >
            <a onClick={(e) => e.preventDefault()}> <Space> {permission} <DownOutlined /> </Space> </a>
          </Dropdown>
          <XIcon className='w-[24px] cursor-pointer hover:text-[#000]' onClick={
            () => {
              setProjectMember(projectMember.filter(member => member.email !== email))
              setToDel(prev => [...prev, id])
            }} />
        </div>
      </div>
    </div>
  )
}

