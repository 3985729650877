

import { Event } from '../Event/event.js'
import { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook'
import { Radio } from 'antd';
import { Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Button } from '../Button'

import {
  ViewGridAddIcon,
  TagIcon,
  TrashIcon,
  PencilIcon,
  FolderIcon,
  DocumentIcon
} from '@heroicons/react/outline'



const CICD = [
  {
    label: '1st menu item',
    key: '0',
  },
  {
    label: '1st menu item',
    key: '1',
  },
];

const branch = [
  {
    label: '1st menu item',
    key: '0',
  },
  {
    label: '1st menu item',
    key: '1',
  },
];



const items1 = [
  {
    label: 'Jenkins',
    key: '0',
  },
];

const items2 = [
  {
    label: 'master',
    key: '0',
  },
];


export const RepoSettings = ({ projectTree }) => {








  return (
    <>
      <div className="flex justify-center">

        <div className="pt-[27px] w-3/4 flex flex-col ">

          <div className="py-4">
            <p className="font-semibold text-[16px] flex justify-start">Интеграция c CI/CD</p>
            <p className=" text-left font-normal text-[15px] text-[#94A3B8] mb-[7px]">Выберите систему настройки CI/CD процесса</p>
            <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 "
              menu={{
                items: items1
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Jenkins
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>

          <div className=" py-4 mt-[18px]">
            <p className="font-semibold text-[16px] flex justify-start">Ветка по умолчанию</p>
            <p className=" text-left font-normal text-[15px] text-[#94A3B8] mb-[7px]">Это базовая ветка, куда создаются мердж реквесты по умолчанию</p>
            <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 py-1 "
              menu={{
                items: items2
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  master
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>


          <div className=" py-4 mt-[18px]">
            <p className="font-semibold text-[16px] flex justify-start">Правила приватности веток</p>
            <p className=" text-left font-normal text-[15px]  text-[#94A3B8]">Вы можете определить правила работы с ветками, и роли, которые могут работать с ними</p>
            <Button
              size="slim"
              color="secondary"
              className=" py-1 mt-[18px] px-[26px] rounded"
            // onClick={() => { history('/project/create') }}
            // disabled={loading}
            >
              Добавить правило
            </Button>
          </div>

        </div>
      </div>

    </>



  )
}