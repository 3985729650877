import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { checkLogged } from './redux/actions/auth.actions'
import { LoaderFull } from './components/Loader/LoaderFull'
import { AuthPage } from './pages/user/AuthPage'
import { NotFound } from './pages/NotFound'
import { RequireAuth } from './hoc/RequireAuth'
import { ForgetPage } from './pages/user/ForgetPassword'
import { Main } from './pages/Main'
// import { Repo } from './pages/Repo'
import { ProjectCreation } from './pages/ProjectCreation'
import { RepoCreation } from './pages/RepoCreation'
import { UserProfilePage } from './pages/user/UserProfilePage'
import { UserPasswordPage } from './pages/user/UserPasswordPage'
import { UserKeysPage } from './pages/user/UserKeys'
import { RepoContent } from './pages/RepoContent'

import { Search } from './pages/Search'

import { Project } from './pages/Project'
import { Test } from './components/Test'


function App() {
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    // check local storage and load to store
    dispatch(checkLogged()).then(res => {
      setChecking(false)
    })
  }, [])

  if (checking) return <LoaderFull />

  return (
    <>
      <ToastContainer hideProgressBar />

      <Routes>
        <Route path="/user/register" element={<AuthPage isLogin={false} />} />
        <Route path="/user/login" element={<AuthPage />} />
        <Route path="/user/forget" element={<ForgetPage />} />

        {/*AUTH NEED Start*/}
        <Route path="/" element={<Navigate to="/main/project" replace />} />

        <Route path="/main/:id" element={
          <RequireAuth>
            <Main />
          </RequireAuth>
        } />

        <Route path="/user" element={<Navigate to="/user/profile" replace />} />
        <Route path="/user/profile" element={
          <RequireAuth>
            <UserProfilePage />
          </RequireAuth>
        } />

        <Route path="/user/password" element={
          <RequireAuth>
            <UserPasswordPage />
          </RequireAuth>
        } />


        <Route path="/user/sshkeys" element={
          <RequireAuth>
            <UserKeysPage />
          </RequireAuth>
        } />

        {/* <Route path="/repo" element={
          <RequireAuth>
            <Repo />
          </RequireAuth>
        } /> */}

        <Route path="/project/create" element={
          <RequireAuth>
            <ProjectCreation create={true} />
          </RequireAuth>
        } />


        <Route path="/project/modify/:project" element={
          <RequireAuth>
            <ProjectCreation create={false} />
          </RequireAuth>
        } />


        {/* <Route path="/repo/create" element={
          <RequireAuth>
            <RepoCreation />
          </RequireAuth>
        } /> */}


        <Route path="/user/project/:id/*" element={
          <RequireAuth>
            <Project />
          </RequireAuth>
        } />


        <Route path="/user/test/:id/:typeparam" element={
          <RequireAuth>
            <Test />
          </RequireAuth>
        } />


        <Route path="/user/repo/:id/:projectname/:typeparam" element={
          <RequireAuth>
            <RepoContent />
          </RequireAuth>
        } />


        <Route path="/user/repo/:id/:projectname/:typeparam/:target/:source" element={
          <RequireAuth>
            <RepoContent />
          </RequireAuth>
        } />

        <Route path="/user/search/:req" element={
          <RequireAuth>
            <Search />
          </RequireAuth>
        } />




        {/*<Route path="/project/settings" element={*/}
        {/*  <RequireAuth>*/}
        {/*    <SettingsPage />*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*<Route path="/project/main" element={*/}
        {/*  <RequireAuth>*/}
        {/*    <ProjectPage />*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*AUTH NEED End*/}

        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  )
}
export default App;
