import { Divider, Dropdown, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../Button'
import { CogIcon } from '@heroicons/react/outline'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { useHttp } from '../../hooks/http.hook'
import { login } from '../../redux/actions/auth.actions'
import { useDispatch } from 'react-redux'
import { Select } from 'antd';
import { useGit } from '../../hooks/git.hook'
import {
  AutoComplete,
  Input
} from 'antd';

const { Option } = Select;


const Header = (props) => {
  const params = useParams()
  let id = params.id
  const [size, setSize] = useState('middle');
  const { gitRequest } = useHttp()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [search, setSearch] = useState([])
  const location = useLocation()
  const history = useNavigate()
  const [selectedPr, setSelectedPr] = useState("Выберите проект")
  const [projects, setProjects] = useState([])
  const violetIfUrlIncludes = 'main'
  const [profileMenu] = useState(
    [
      {
        label: <Link to='/user/profile'>Профиль</Link>,
        key: '0',
      },
      {
        label: <Link to='/user/password'>Сменить пароль</Link>,
        key: '1',
      },
      {
        label: <Link to='/user/sshkeys'>Настройки доступа</Link>,
        key: '2',
      },
      {
        label: <Link to='/user/login'>Выйти</Link>,
        onClick: () => { logOut() },
        key: '3',
      },
    ]
  )
  const {
    getSingleProject,
    getSearch
  } = useGit()


  const [searchVal, setSearchhVal] = useState("")


  // console.log("HRADER PARAMS", id)

  useEffect(() => {
    try {
      (async () => {
        const getAllProjects = await gitRequest(`/api/v4/users/${auth.user.id}/projects`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        let newPr = getAllProjects.map(el => {
          return {
            label: <Link to={`/user/project/${el.id}/byproject`} >{el.name}</Link>,
            value: el.name,
            id: el.id
          }
        })

        setProjects(newPr)

        if (id !== "project") {
          let tmpObj = await getSingleProject(id, auth)
          setSelectedPr(tmpObj.name)
        }


      })()

    } catch (e) {
      console.log(e)
    }
  }, [])


  useEffect(() => {
    try {
      (async () => {

        if (id !== "project") {
          let tmpObj = await getSingleProject(id, auth)
          setSelectedPr(tmpObj.name)
        }

      })()

    } catch (e) {
      console.log(e)
    }
  }, [id])


  const searchChangeHandler = async value => {
    setSearchhVal(value)
  }

  const selectHandler = async value => {
    setSearchhVal(value)
    let projectId = search.find(pr => pr.value === value).id

    if (projectId !== null) {
      history(`/user/project/${projectId}/byproject`)
    }
  }


  function logOut() {
    dispatch(login(null))
  }



  useEffect(() => {
    try {
      const handler = setTimeout(async () => {
        // setSearch(await getSearch(searchVal, id, auth))

        if (searchVal !== "") {
          history(`/user/search/${searchVal}`)
        }
      }, 2000)
      return () => {
        clearTimeout(handler)
      }
    } catch (e) {
      console.log(e)
    }
  }, [searchVal])




  const NoAutocomplete = () => {
    return (
      <>
        <input type="text" style={{ display: 'none' }} value=' ' onChange={() => { }} />
        <input type="password" style={{ display: 'none' }} value=' ' onChange={() => { }} />
      </>
    )
  }





  return (
    <nav className="bg-white rounded border-b border-func-border px-2 sm:px-10 py-1.5">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        {/*LEFT side*/}
        <div className="flex gap-6 items-center">
          {/*logo*/}
          <a className="flex items-center" href="/">
            <span className="drop-shadow-md text-primary-violet font-bold font-oxanium text-shadow">{`<ONTORY/>`}</span>
          </a>

          {/*search*/}
          <div className="flex items-center">

            <NoAutocomplete />
            {/* <input type="text" id="message" autoComplete="off"
              style={{
                width: '300px',
              }}
              placeholder="search"


              onChange={e => { searchChangeHandler(e.target.value) }}
            /> */}

            <Input autoComplete="new-state"

              style={{
                width: '300px',
              }}
              placeholder="Поиск"


              onChange={e => { searchChangeHandler(e.target.value) }}
            />
            <input type="password" id="password" autoComplete="new-password" disabled style={{
              display: 'none',
            }}
            />

          </div>

          {!params.req && id ?
            <div className="flex items-center h-full gap-1.5">



              <Dropdown
                menu={{
                  items: projects,
                  // onClick,
                }}
                trigger={['click']}
              >
                <span className='cursor-pointer' >
                  <Space>
                    {selectedPr}
                    <DownOutlined className='mt-1' />
                  </Space>
                </span>
              </Dropdown>



              <Divider type="vertical" className="h-5 mt-1.5" />


              {selectedPr !== "Выберите проект" ?

                <div

                  className={`flex items-center gap-2 ${location.pathname.includes(violetIfUrlIncludes) ? 'text-slate-400' : 'text-slate-400'} cursor-pointer`}>
                  <CogIcon className="h-5 w-5 mt-1" />
                  <span onClick={() => { history(`/project/modify/${id}`) }} className="leading-6">Настройки проекта</span>

                </div>
                :
                <></>

              }
              <></>



            </div>
            :
            <></>
          }


          {/* :
            <></>
          } */}
        </div>

        {/*RIGHT side*/}
        <div className="flex gap-1 items-center ">
          <Dropdown
            menu={{
              items: profileMenu,
            }}
            trigger={['click']}
          >
            <Button color="ghost" size={'none'} className="flex p-1 rounded-full">
              {auth?.user?.photo ?
                <img
                  className="w-8 h-8 rounded-full"
                  src={auth?.user?.photo}
                  alt="Ваш профиль"
                />
                :
                <img
                  className="w-8 h-8 rounded-full"
                  src={"https://git.app.ontory.ru" + auth.user.avatar_url.slice(25)}
                  alt="Ваш профиль"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = auth.user.avatar_url;
                  }}
                />
              }
            </Button>
          </Dropdown>
        </div>
      </div>
    </nav >
  )
}

export default Header