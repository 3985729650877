
import { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook'
import { Radio } from 'antd';
import { Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useSelector } from 'react-redux'
import CodeMirror from '@uiw/react-codemirror'
import { Button } from '../Button'
import { useGit } from '../../hooks/git.hook'
import moment from 'moment'
import 'moment/locale/ru'
import { linter, openLintPanel } from '@codemirror/lint'
import { javascript, esLint } from '@codemirror/lang-javascript'
import Linter from 'eslint4b-prebuilt'
import {
  TrashIcon,
  PencilIcon,
  ArrowNarrowLeftIcon
} from '@heroicons/react/outline'
import { useMessage } from '../../hooks/message.hook'


let lang = {
  js: [[javascript({ jsx: true }), linter(esLint(new Linter()))], "snippet.js"]
}

export const Code = ({ path, setPath, id, projectName, selectedBranch, setType, setChanges, changes}) => {
  const message = useMessage()
  const { fixAvatar, sendMergeRequest, getRepoBranches, getMergeRequest, getDiffBetweenBranches, getProjectTreeByBranch, getCode, updateFile, getCommits, deleteFile } = useGit()
  const [code, setCode] = useState("")
  const [readOnly, setReadOnly] = useState(true)
  const { gitTextRequest } = useHttp()
  const auth = useSelector(state => state.auth)
  const { TextArea } = Input;
  const [commit, setCommit] = useState("")
  const [lastCommit, setLastCommit] = useState([])
  const [extensions, setExtensions] = useState(lang.js[0])
  const items = [
    {
      label: selectedBranch,
      key: selectedBranch,
    },
  ];
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };



  useEffect(() => {
    try {
      (async () => {
        // const getCode = await gitTextRequest(`/api/v4/projects/${id}/repository/files/${path}/raw?ref=${selectedBranch}`, 'GET', null, { authorization: 'Bearer ' + auth.token })  
        setCode(await getCode(id, auth, path, selectedBranch))
        setLastCommit(await getCommits(id, auth))

      })()
    } catch (e) {
      console.log(e)
    }
  }, [])



  const updateHandler = async () => {
    message(await updateFile(id, auth, path, selectedBranch, commit, code))
  }

  const deleteHandler = async () => {
    message(await deleteFile(id, auth, path, selectedBranch, "DELETED"))
    setPath("")
    setChanges(changes + 1)
  }

  const backHandler = () => {
    setType("tree")
    let tmpPath = path
    tmpPath = tmpPath.split("/")
    tmpPath.pop()
    tmpPath = tmpPath.join("/")
    setPath(tmpPath)
  }



  return (
    <>
      <div className="flex justify-center">

        <div className=" w-full flex flex-col ">

          <div className="flex flex-row gap-4">
            <ArrowNarrowLeftIcon className="w-6 cursor-pointer text-[#94A3B8] hover:text-[#000]" onClick={() => { backHandler() }} />
            <Dropdown className="flex justify-start bg-[#F5F5F5] rounded-full px-4 py-1"
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedBranch}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>

            {readOnly ?
              <div className="flex flex-row items-center gap-[5px] h-[32px]"><p>{projectName}/{path}</p></div>
              :
              <div className="flex flex-row items-center gap-[5px]"><p>{projectName}/</p><Input value={path} /></div>

            }

          </div>



          <div className="flex flex-row ">
            <div className="w-full">
              <div className="flex flex-row justify-between bg-[#F8FAFC] py-[7px] px-[15px] mt-[14px]">

                <div className="flex   justify-between">
                  <div className="flex flex-row">
                    <img src={fixAvatar(auth.user.avatar_url)} className="w-6 h-6 bg-[#D9D9D9] rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = auth.user.avatar_url;
                      }}
                    ></img>
                    <p className="font-semibold text-base ml-2">{auth.user.name}</p>
                  </div>
                  <div className="pl-[10px]">
                    <p className="font-normal text-base  text-[#94A3B8]">Описание последнего коммита</p>
                  </div>
                </div>
                <div className="flex flex-row text-[#94A3B8]">
                  <p className="mr-[19px]">{lastCommit[0]?.short_id} от {moment(lastCommit[0]?.committed_date).format("DD MMM")}</p>
                  <PencilIcon className="w-6 mr-[9px] cursor-pointer hover:text-[#000]" onClick={() => { readOnly ? setReadOnly(false) : setReadOnly(true) }} />
                  <TrashIcon className="w-6 cursor-pointer hover:text-[#000]" onClick={() => { deleteHandler() }} />
                </div>
              </div>
            </div>
          </div>



          <CodeMirror
            // ref={view}
            value={code}
            height="350px"
            readOnly={readOnly}
            style={{ width: '100%' }}
            extensions={extensions}
            onChange={(value, viewUpdate) => {
              setCode(value)
            }}
          />


          {
            !readOnly ?
              <div className='flex flex-row gap-4 mt-4'>

                <div>
                  <img src={fixAvatar(auth.user.avatar_url)} className="w-6 h-6 bg-[#D9D9D9] rounded-full"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = auth.user.avatar_url;
                    }}
                  ></img>

                </div>


                <div className='w-full flex flex-col'>
                  <p>Зафиксировать изменения</p>
                  <Input placeholder="Create file/test.txt"
                    onChange={(e) => {
                      setCommit(e.target.value)

                    }} />
                  <TextArea className='mt-2 mb-[19px]'
                    placeholder="Добавьте опциональное описание "
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />

                  {/* <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical">
                      <Radio value={1}>Закоммитить напрямую в ветку <span className="text-[#0066C3] font-semibold">{selectedBranch}</span></Radio>
                      <Radio value={2}>Создать новую ветку и мердж реквест в ветку <span className="text-[#0066C3] font-semibold">{selectedBranch}</span></Radio>
                    </Space>
                  </Radio.Group> */}

                  <Button
                    type="primary"
                    size="md"
                    className=" py-3 px-[114px] w-min mt-[29px]"
                    onClick={updateHandler}
                  // disabled={loading}
                  >
                    Добавить
                  </Button>
                </div>
              </div>
              :
              <></>
          }
        </div>
      </div>
    </>
  )
}