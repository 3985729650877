import React, { useEffect, useState } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useMessage } from '../hooks/message.hook'
import { useHttp } from '../hooks/http.hook'
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../components/Button'
import { Input } from 'antd'
import { Member } from '../components/Member/member'
import { DownOutlined, AppstoreAddOutlined } from '@ant-design/icons'
import { Dropdown, Space, Select, Checkbox, Radio } from 'antd'
import {
  PlusIcon,
} from '@heroicons/react/outline'
import { Divider, Modal } from 'antd'
import { useGit } from '../hooks/git.hook'

const { TextArea } = Input
const { Option } = Select
export const ProjectCreation = ({ create = false }) => {

  const params = useParams()
  let projectId = params.project
  const [permission, setPermission] = useState("Чтение и запись")
  const [projectName, setProjectName] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [changePerm, setChangePerm] = useState({})
  const [projectNameFromGit, setProjectNameFromGit] = useState("")
  const message = useMessage()
  const { gitRequest, request } = useHttp()
  const auth = useSelector(state => state.auth)

  const history = useNavigate()
  const [jiraUrl, setJiraUrl] = useState("")
  const [jiraProjectName, setJiraProjectName] = useState("")
  const [jiraUser, setJiraUser] = useState("")
  const [jiraToken, setJiraToken] = useState("")
  const [configureProject, setConfigureProject] = useState(false)
  const { createEditJiraIntgration } = useGit()
  const [archRadio, setArchRadio] = useState('monolith')


  const items = [
    {
      label: 'Администратор',
      key: 'Администратор',
    },
    {
      type: 'divider',
    },
    {
      label: 'Чтение и запись',
      key: 'Чтение и запись',
    },
    {
      label: 'Только чтение',
      key: 'Только чтение',
    },
  ]
  let mmbr = {
    id: auth.user.id,
    name: auth.user.name,
    email: auth.user.email,
    avatar: auth.user.avatar_url,
    permissions: "Администратор"
  }
  const [projectMember, setProjectMember] = useState([mmbr])
  const [toDel, setToDel] = useState([])
  const [toAdd, setToAdd] = useState([])
  const onClick = ({ key }) => {
    setPermission(key)
  }
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState("")
  const handleCancel = () => {
    setShow(false)
  }

  const showModal = () => {
    setShow(true)
  }




  const onChangeArchRadio = (e) => {
    // console.log('radio checked', e.target.value)
    setArchRadio(e.target.value)
  }

  const options = [
    {
      value: 'Микросервисы',
      label: 'Это проект содержит микросервисную архитектуру',

    },
    {
      value: 'Монолит',
      label: 'Это проект содержит монолитную архитектуру',

    },
    // Добавьте другие элементы по необходимости
  ]




  useEffect(() => {

    (async () => {
      const jiraData = await request('/api/jira/getJiraData', 'POST', { repoId: projectId })


      // console.log("jiraData: ", jiraData)
      setJiraUrl(jiraData?.project.jiraUrl)
      setJiraProjectName(jiraData?.project.jiraProjectName)
      setJiraUser(jiraData?.project.jiraUser)
      setJiraToken(jiraData?.project.jiraToken)



    })()

  }, [])




  const onChangeConfigureProject = (e) => {
    // console.log(`checked = ${e.target.checked}`)
    setConfigureProject(e.target.checked)
  }


  const handleOk = async e => {
    setProjectMember(prev => ([...prev, { name: "", email: email, avatar: "", permissions: permission }]))
    setEmail(null)
    handleCancel()
    setToAdd(prev => ([...prev, { name: "", email: email, avatar: "", permissions: permission }]))
  }

  useEffect(() => {
    try {
      (async () => {
        const getProjectName = await gitRequest(`/api/v4/projects/${projectId}`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setProjectNameFromGit(getProjectName.name)
        const getProjectUsers = await gitRequest(`/api/v4/projects/${projectId}/members`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        let projectUsers = getProjectUsers.map(el => {
          return {
            id: el.id,
            permission: el.access_level
          }
        })


        for (let i = 0; i < projectUsers.length; i++) {
          const usersData = await gitRequest(`/api/v4/users/${projectUsers[i].id}`, 'GET', null, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })

          if (auth.user.email !== usersData.email) {
            setProjectMember(prev => ([...prev, { id: usersData.id, name: usersData.username, email: usersData.email, avatar: usersData.avatar_url, permissions: getPermissionCode(projectUsers[i].permission) }]))
          }
        }
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    for (let i = 0; i < projectMember.length; i++) {
      if (projectMember[i].email == changePerm.email) {
        projectMember[i].permissions = changePerm.permission
      }
    }
  }, [changePerm])

  function getPermissionCode(perm) {
    const allPermissionsCode = {
      "Администратор": 50,
      "Чтение и запись": 30,
      "Только чтение": 10,
      "50": "Администратор",
      "30": "Чтение и запись",
      "10": "Только чтение"
    }
    return allPermissionsCode[perm]
  }

  useEffect(() => {
    setProjectName(projectName.replaceAll(" ", "_"))
  }, [projectName])


  const handleAdd = async e => {
    try {
      // let name = projectName.replaceAll(' ', '_')
      if (create) {
        // console.log("create")
        // /(?(?=[a-z])[^ite]|^$)/
        if (projectName.match(/[^\d\sA-Z_]/gi) !== null) {
          message(`Недопустимые символы: ${projectName.match(/[^\d\sA-Z_]/gi)}`) // @ и .
        } else {
          const createProject = await gitRequest(`/api/v4/projects`, 'POST', { name: projectName, path: projectName }, { authorization: 'Bearer ' + auth.token })


          const updateOrCreateJira = await request('/api/jira/setJiraData', 'POST', { jiraUrl, jiraProjectName, jiraUser, jiraToken, repoId: createProject.id })



          // console.log("createProject", createProject.id)
          message(`Проект ${createProject.id} создан`)

          if (createProject.id && configureProject) {

            try {
// 
              console.log("---")
              const gemini = await request('/api/gemini/generate', 'POST', { projectDescription, archRadio })

              // console.log("gemini---1", gemini.gemini.text)
              // let clearJson = JSON.parse(gemini.gemini.text.replace("```json", "").replace("```", ""))

              // Удаляем все вхождения "```json" и "```"
              let clearStr = gemini.gemini.text.replace(/```json/g, "").replace(/```/g, "").trim()

              // Оборачиваем в квадратные скобки и добавляем запятые
              if (clearStr[0] !== "[") {
                clearStr = "[" + clearStr.replace(/}\s*{/g, "},{") + "]"
              } else {
                clearStr = "" + clearStr.replace(/}\s*{/g, "},{") + ""
              }


              // Парсим JSON
              let clearJson = JSON.parse(clearStr)

              console.log("Parsed JSON:", clearJson)


              const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


              const createFoldersAndFiles = async () => {
                const projectId = createProject.id // замените на реальный ID проекта
                const token = "glpat-wP_QMbPC8K9uY5RxY4gh"

                for (const file of clearJson) {
                  // Заменяем :id на реальный ID проекта и убираем начальную часть пути до /files/
                  const filePath = file.file.replace(':id', projectId).replace('/api/v4/projects/' + projectId + '/repository/files/', '')

                  // Разделяем путь на части
                  const pathParts = filePath.split('/')
                  let currentPath = ""

                  for (const [index, part] of pathParts.entries()) {
                    if (index === pathParts.length - 1) break // Пропускаем последний элемент, так как это файл

                    currentPath += (currentPath ? "/" : "") + part

                    // Создаем папку
                    const folderPath = `/api/v4/projects/${projectId}/repository/files/${encodeURIComponent(currentPath)}%2F.gitkeep`
                    try {
                      await gitRequest(folderPath, 'POST', {
                        branch: file.content.branch,
                        commit_message: `Create folder ${currentPath}`,
                        content: ""
                      }, { authorization: 'Bearer ' + token })

                      console.log(`Папка ${currentPath} создана`)
                    } catch (error) {
                      if (error.message.includes("already exists")) {
                        console.log(`Папка ${currentPath} уже существует`)
                      } else {
                        console.log(`Ошибка при создании папки ${currentPath}:`, error.message)
                      }
                    }
                  }

                  // Создаем файл
                  try {
                    const response = await gitRequest(`/api/v4/projects/${projectId}/repository/files/${encodeURIComponent(filePath)}`, 'POST', file.content, { authorization: 'Bearer ' + token })
                    console.log(`Создание файла ${filePath}:`, response)
                  } catch (error) {
                    console.log(`Ошибка при создании файла ${filePath}:`, error.message)
                  }

                  await sleep(200) // Пауза в 200 мс между запросами
                }
              }

              createFoldersAndFiles()




            } catch (e) {
              console.log(e)
            }

          }














          const getAllUsers = await gitRequest(`/api/v4/users`, 'GET', null, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })

          for (let j = 1; j < projectMember.length; j++) {
            for (let i = 0; i < getAllUsers.length; i++) {
              let searchTerm = projectMember[j].email
              let userId = getAllUsers.find(user => user.email === searchTerm).id
              if (userId) {
                const addUserToProject = await gitRequest(`/api/v4/projects/${createProject.id}/members`, 'POST', { user_id: userId, access_level: getPermissionCode(projectMember[j].permissions) }, { authorization: 'Bearer ' + auth.token })
                message("Пользователь добавлен")
                break
              }
            }
          }



          const jira = await createEditJiraIntgration(createProject.id, auth, jiraUrl, jiraUser, jiraToken)
          // message(jira)
          history('/')
        }

      } else {
        console.log("else")

        
        const updateOrCreateJira = await request('/api/jira/setJiraData', 'POST', { jiraUrl, jiraProjectName, jiraUser, jiraToken, repoId: projectId })
        
        console.log("updateOrCreateJira", updateOrCreateJira)
        // done rename
        const changeProjectName = await gitRequest(`/api/v4/projects/${projectId}`, 'PUT', { name: projectNameFromGit }, { authorization: 'Bearer ' + auth.token })
        message("Проект переименован")

        // done add members
        const getAllUsers = await gitRequest(`/api/v4/users`, 'GET', null, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })
        for (let j = 1; j < projectMember.length; j++) {
          for (let i = 0; i < getAllUsers.length; i++) {
            let searchTerm = projectMember[j].email
            let userId = getAllUsers.find(user => user.email === searchTerm).id

            if (userId && !projectMember[j].name) {
              const addUserToProject = await gitRequest(`/api/v4/projects/${projectId}/members`, 'POST', { user_id: userId, access_level: getPermissionCode(projectMember[j].permissions) }, { authorization: 'Bearer ' + auth.token })
              message("Пользователь добавлен")
              break
            }
          }
        }

        // delete members - done
        if (toDel.length) {
          for (let j = 0; j < toDel.length; j++) {
            const deleteProjectMember = await gitRequest(`/api/v4/projects/${projectId}/members/${toDel[j]}`, 'DELETE', null, { authorization: 'Bearer ' + auth.token })
            message("Пользователь удалён")
          }
        }

        // done change member
        for (let j = 1; j < projectMember.length; j++) {
          const changeProjectMember = await gitRequest(`/api/v4/projects/${projectId}/members/${projectMember[j].id}`, 'PUT', { access_level: getPermissionCode(projectMember[j].permissions) }, { authorization: 'Bearer ' + "glpat-wP_QMbPC8K9uY5RxY4gh" })
          message("Пользователь изменён")
        }

      }
      const jira = await createEditJiraIntgration(projectId, auth, jiraUrl, jiraUser, jiraToken)
    } catch (error) {
      message(error)
    }
  }

  const listOfMembers = projectMember.map((member, idx) =>
    <li key={idx}><Member
      id={member.id}
      name={member.name}
      setChangePerm={setChangePerm}
      email={member.email}
      permissions={member.permissions}
      avatar={member.avatar}
      setProjectMember={setProjectMember}
      projectMember={projectMember}
      setToDel={setToDel}
      create={create}
    /></li>
  )


  return (
    <HScreen
      project={{
        name: 'some 2Project',
      }}
    >
      <div className='flex justify-center content-center align-center h-full'>
        <div className="flex flex-col justify-center w-4/12  content-center align-center">

          {
            create ?
              <div className='flex justify-center font-sans text-3xl font-semibold '>Создание проекта</div>
              :
              <div className='flex justify-center font-sans text-3xl font-semibold '>Настройки проекта</div>
          }
          {create ?
            <div className="flex flex-col gap-[8px]">
              <Input
                placeholder="Название вашего проекта"
                className="mt-6"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />

              <div className="flex flex-col gap-[8px]">
                <TextArea
                  rows={4}
                  placeholder="Описание вашего проекта"
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                  className="" // Используем flex-grow для заполнения оставшегося пространства
                />

                {/* <Select

                  placeholder="Предварительно сконфигурированный проект"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  className="w-1/3 h-full"
                >
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>
                      <div className="flex flex-row items-start text-[12px]">
                        <div className='h-full'>
                          <AppstoreAddOutlined />
                        </div>
                        <div className=" whitespace-normal break-words flex items-center justify-center">
                          {option.label}
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select> */}


                <div className='flex flex-col  w-full gap-[8px]'>

                  <Checkbox onChange={onChangeConfigureProject}>Сконфигурировать файлы проекта на основе описания</Checkbox>

                  {
                    configureProject ?


                      <div>
                        <div>Архитектура конфигурации</div>
                        <Radio.Group onChange={onChangeArchRadio} value={archRadio}>
                          <Radio value={'monolith'}>Монолит</Radio>
                          <Radio value={"microservice"}>Микросервисы</Radio>
                        </Radio.Group>
                      </div>

                      :

                      <></>


                  }

                </div>


              </div>
            </div>

            :

            <Input placeholder="Название вашего проекта" className='mt-6'
              value={projectNameFromGit}
              onChange={e => { setProjectNameFromGit(e.target.value) }}
            />

          }
          <div className='border-b pt-4'></div>
          <p className='font-semibold text-xl mt-4'>Команда</p>

          <div>
            <ul>{listOfMembers}</ul>
            <div className='flex items-center mt-4  text-[#94A3B8]  hover:text-[#000] cursor-pointer' id='addMember' onClick={showModal}>
              <PlusIcon className='w-[20px] ' />
              <p className=" text-[16px]  ml-1.5 ">Добавить участника к проекту</p>
            </div>
          </div>

          <div className='border-b pt-4'></div>
          <div className='flex mt-4 items-center'>
            <p className='font-semibold font-sans text-xl '>Интеграции</p>
            <p className='text-[#9CA3AF] text-[13px] font-normal font-sans ml-3'>&#8226;  Настройте интеграцию к проекту Jira</p>

          </div>

          <div className='flex flex-row gap-[10px]'>
            <Input placeholder="JIRA server URL" value={jiraUrl} className='mt-4 w-2/3' onChange={e => { setJiraUrl(e.target.value) }} />
            <Input placeholder="Project Key" value={jiraProjectName} className='mt-4 w-1/3' onChange={e => { setJiraProjectName(e.target.value) }} />
          </div>
          <Input placeholder="JIRA UserName" value={jiraUser} className='mt-4' onChange={e => { setJiraUser(e.target.value) }} />
          <Input placeholder="JIRA token API" value={jiraToken} type="password" className='mt-4' onChange={e => { setJiraToken(e.target.value) }} />



          <div className='border-b pt-4'></div>

          <Button
            // size="slim"
            className=" mt-4"
            onClick={handleAdd}
          >
            {create ? "Создать проект" : "Сохранить изменения"}
          </Button>
        </div>
      </div>


      <Modal
        open={show}
        title={null}
        footer={null}
        centered
        width={640}
        onCancel={handleCancel}
      >
        <div className='flex flex-col gap-2'>
          <p className="text-2xl block">Добавление участника</p>
          <Divider className="my-2" />
        </div>
        <div className="flex flex-col gap-4">
          <div className='flex gap-6 items-center justify-between'>
            <Input
              className="text-blueGray-300 w-[400px]"
              placeholder="Email"
              id="email"
              name="email"
              autoComplete="off"
              value={email}
              onChange={e => { setEmail(e.target.value) }}
            />
            <Dropdown menu={{
              items,
              onClick,
            }} trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space> {permission} <DownOutlined /> </Space>
              </a>
            </Dropdown>
          </div>
          <div className="flex justify-between items-center gap-4">
            <Button
              // size="slim"
              className="w-1/3 mt-2"
              onClick={handleOk}
            >
              Добавить
            </Button>
            <Button
              // size="slim"
              color="secondary"
              className="w-full hidden"
              onClick={handleCancel}
            >
              Отмена
            </Button>
          </div>
        </div>
      </Modal>
    </HScreen>
  )
}