import React, { useEffect, useState } from 'react'
import HScreen from '../layout/hScreen'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { LineMenu } from '../components/LineMenu'
import { useGit } from '../hooks/git.hook'
import { NoRepoContent } from '../components/NoRepoContent';
import { YesRepoContent } from '../components/YesRepoContent';
import { RepoSettings } from '../components/RepoSettings';
import { Merj } from '../components/Merj';
import { Code } from '../components/Code';
import { Releases } from '../components/Releases';import {
    AcademicCapIcon,
} from '@heroicons/react/outline'

export const RepoContent = () => {
    const params = useParams()
    let id = params.id
    const auth = useSelector(state => state.auth)
    const [projectTree, setProjectTree] = useState([])
    const type = params.typeparam
    const projectName = params.projectname
    const { getProjectTreeByProject } = useGit()


    useEffect(() => {
        try {
            (async () => {
                setProjectTree(await getProjectTreeByProject(id, auth))
            })()
        } catch (e) {
            console.log(e)
        }
    }, [])



    const Switcher = () => {
        switch (type) {
            case "content":
                return (
                    <div>
                        {projectTree.length ?
                            <YesRepoContent id={id} projectName={projectName} />
                            :
                            <NoRepoContent id={id} setProjectTree={setProjectTree} />
                        }

                        {/* <Content /> */}
                    </div>
                )// component
            case "merj":
                return (<Merj />) // component
            case "reliz":
                return (<Releases />) // component
            // case "tag":
            //     return (<p>tag</p>) // component
            case "settings":
                return (<RepoSettings />) // component
        }
    }




    return (
        <HScreen
        //   project={{
        //     name: 'some Project',
        //   }}
        >

            <div className="flex flex-col w-full "  >
                <div className='bg-[#F8FAFC] '>
                    <center>
                        <div className='w-3/4'>
                            <div className="flex flex-row gap-2 mb-7 mt-[7px]"><AcademicCapIcon className="w-[24px]" /> <p>{projectName}</p></div>
                            <LineMenu items={
                                [

                                    { id: 0, text: 'Код', href: `/user/repo/${id}/${projectName}/content` },
                                    { id: 1, text: 'Мердж Реквесты', href: `/user/repo/${id}/${projectName}/merj` },
                                    { id: 2, text: 'Релизы', href: `/user/repo/${id}/${projectName}/reliz` },
                                    // { id: 3, text: 'Тэги', href: `/user/repo/${id}/${projectName}/tag` },
                                    { id: 3, text: 'Настройки', href: `/user/repo/${id}/${projectName}/settings` },
                                ]
                            } />
                        </div>
                    </center>
                </div>



            </div>

            <Switcher />
        </HScreen >
    )
}