
import { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Space } from 'antd';
import { Code } from '../Code';
import { useGit } from '../../hooks/git.hook'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Button } from '../Button'
import 'moment/locale/ru'
import { useMessage } from '../../hooks/message.hook'

import {
  ViewGridAddIcon,
  TagIcon,
  FolderIcon,
  DocumentIcon,
  ArrowNarrowLeftIcon,
  DocumentAddIcon
} from '@heroicons/react/outline'


export const YesRepoContent = ({ id, projectName }) => {
  const message = useMessage()
  const auth = useSelector(state => state.auth)
  const [path, setPath] = useState("")
  const [type, setType] = useState("tree")
  // const [value, setValue] = useState(1);
  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState("main")
  const [projectTree, setProjectTree] = useState([])
  const [lastCommit, setLastCommit] = useState([])
  const [readme, setReadme] = useState("")
  const [tags, setTags] = useState([])
  const [releases, setReleases] = useState([])
  const [showModalBranch, setShowModalBranch] = useState(false)
  const [onlyBranches, setOnlyBranches] = useState([])
  const [newBranchFrom, setNewBranchFrom] = useState("main")
  const [newBranchName, setNewBranchName] = useState("")
  const [newFileName, setNewFileName] = useState("")
  const [showModalFile, setShowModalFile] = useState(false)
  const [fileOrFolder, setFileOrFolder] = useState("Файл")
  const [changes, setChanges] = useState(0)///
  const [somethingChanges, setSomothingChanges] = useState(0)
  const { fixAvatar, sendMergeRequest, getRepoBranches,
    getMergeRequest, getDiffBetweenBranches, getProjectTreeByBranch,
    getCommits, getCode, getTags, getReleases, createBranch, createFile } = useGit()
  const options = [
    {
      label: 'Файл',
      value: 'Файл',
    },
    {
      label: 'Папка',
      value: 'Папка',
    },
  ];
  const addBranch = [
    {
      type: 'divider',
      label: ''
    },
    {
      key: 'Create new branch',
      label: 'Create new branch'
    }
  ]

  useEffect(() => {
    try {
      (async () => {
        // setBranches(await getRepoBranches(id, auth))
        let tmpBranches = await getRepoBranches(id, auth)
        setOnlyBranches(tmpBranches)
        tmpBranches = [...tmpBranches, ...addBranch]
        setBranches(tmpBranches)
        // setSelectedBranch(branches[0].label)
        setLastCommit(await getCommits(id, auth))
        setReadme(await getCode(id, auth, "README.md", selectedBranch)) /////
        setTags(await getTags(id, auth))
        setReleases(await getReleases(id, auth))
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])




  useEffect(() => {
    try {
      (async () => {
        let tmpBranches = await getRepoBranches(id, auth)
     
        setOnlyBranches(tmpBranches)
        tmpBranches = [...tmpBranches, ...addBranch]
        setBranches(tmpBranches)
        setTags(await getTags(id, auth))
      })()
    } catch (e) {
      console.log(e)
    }
  }, [somethingChanges]) ////// tyt







  useEffect(() => {
    try {
      (async () => {
        setProjectTree(await getProjectTreeByBranch(id, auth, selectedBranch, path))
        setReadme(await getCode(id, auth, "README.md", selectedBranch)) /////
      })()
    } catch (e) {
      console.log(e)
    }
  }, [selectedBranch, path, type])

  const fileClickHandler = (path, type) => {
    setPath(path)
    setType(type)
  }

  const onChange3 = ({ target: { value } }) => {
    setFileOrFolder(value)
  };

  // const onChange = (e) => {
  //   setValue(e.target.value);
  // };


  useEffect(() => {
    try {
      (async () => {
        setProjectTree(await getProjectTreeByBranch(id, auth, selectedBranch, path))


      })()
    } catch (e) {
      console.log(e)
    }
  }, [changes])



  const createReadMe = async () => {
    message(await createFile(id, auth, "README.md", "", selectedBranch, "Файл"))
    message("README.md создан")
    setChanges(changes + 1)
  }

  const File = ({ type, name, lastCommit, date, path }) => {
    return (
      <>
        <div className='flex flex-row  mt-2 cursor-pointer hover:bg-[#F8FAFC]'
          onClick={() => fileClickHandler(path, type)
          }
        >
          <div className="flex flex-row gap-2 items-center w-1/3">{type == "tree" ? <FolderIcon className="w-5 fill-[#6BACF8] text-[#6BACF8]" /> : <DocumentIcon className="w-5" />} {name}</div>
          <p className="text-[#94A3B8] w-1/3 flex justify-start">{lastCommit}</p>
          <p className="text-[#94A3B8] w-1/3 flex justify-end">{date}</p>
        </div>
      </>
    )
  }

  const clickHandlerBranch = ({ key }) => {
    if (key === "Create new branch") {
      setShowModalBranch(true)
      setNewBranchName("")
    } else {
      setSelectedBranch(key)
    }
  };

  const clickHandlerFile = ({ key }) => {
    setShowModalFile(true)
    setNewFileName("")

  };

  const clickHandlerNewBranch = ({ key }) => {
    setNewBranchFrom(key)
  };

  const backHandler = () => {
    setType("tree")
    let tmpPath = path
    tmpPath = tmpPath.split("/")
    tmpPath.pop()
    tmpPath = tmpPath.join("/")
    setPath(tmpPath)
  }

  const handleModalBranchOk = async () => {
    message(await createBranch(id, auth, newBranchFrom, newBranchName))
    setSomothingChanges(somethingChanges + 1)
    setShowModalBranch(false)
  }


  const handleModalFileOk = async () => {
    message(await createFile(id, auth, newFileName, path, selectedBranch, fileOrFolder))
    setChanges(changes + 1)
    setShowModalFile(false) /////
  }

  const handleCancel = () => {
    setShowModalBranch(false)
    setShowModalFile(false)
  }


  const listOfFiles = projectTree.map((file, idx) =>
    <li key={idx}><File type={file?.type} name={file?.name} lastCommit={file?.lastCommit} date={file?.date} path={file?.path} /></li>
  );

  return (
    <>
      <div className="flex justify-center">

        <div className="pt-[27px] w-3/4 flex flex-col ">
          {type == "tree" ?
            <div>
              <div className="flex flex-row gap-4">{path ?
                <ArrowNarrowLeftIcon className="w-6 cursor-pointer text-[#94A3B8] hover:text-[#000]" onClick={() => { backHandler() }} />
                :
                <></>
              }
                <Dropdown className="flex justify-start bg-[#F5F5F5] rounded-full px-4 py-1"
                  menu={{
                    items: branches,
                    onClick: clickHandlerBranch
                  }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {selectedBranch}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>

                <div className="flex flex-row items-center gap-[5px]"><ViewGridAddIcon className="w-[24px]" /> <p> {branches.length ? branches.length - 2 : 0} {branches.length == 1 ? " ветка" : " ветки"}  </p></div>
                <div className="flex flex-row items-center gap-[5px]"> <TagIcon className="w-[24px]" /> <p> {tags.length} тегов </p></div>
              </div>

              <div className="flex flex-row   ">
                <div className="w-3/4 h-[calc(100vh-196px)] overflow-auto scrollbar-hide md:scrollbar-default">
                
                  <div className="flex flex-row justify-between bg-[#F8FAFC] py-[7px] px-[15px] mt-4">
                    <div className="flex   justify-between">

                 
                      <div className="flex flex-row">
                        <img src={fixAvatar(auth.user.avatar_url)} className="w-6 h-6 bg-[#D9D9D9] rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = auth.user.avatar_url;
                          }}
                        ></img>
                        <p className="font-semibold text-base ml-2">{auth.user.name}</p>
                      </div>
                      <div className="pl-[10px]">
                        <p className="font-normal text-base  text-[#94A3B8]">Описание последнего коммита</p>
                      </div>
                    </div>
                    <div className="flex flex-row text-[#94A3B8]">
                      <p className="mr-[19px]">{lastCommit[0]?.short_id} от {moment(lastCommit[0]?.committed_date).format("DD MMM")}</p>
                      <DocumentAddIcon className="w-6 mr-[9px] cursor-pointer hover:text-[#000]" onClick={clickHandlerFile} />
                      {/* <PencilIcon className="w-6 mr-[9px]" />
                      <TrashIcon className="w-6" /> */}
                    </div>
                  </div>

                  <div className="border-[1px] pb-[45px] px-8">
                    <ul>{listOfFiles}</ul>
                  </div>


                  {typeof projectTree?.find((i) => i?.name?.toLowerCase() === "readme.md".toLowerCase()) !== "undefined" ?
                    // projectTree.find((i) => i?.name?.toLowerCase() === "README.md".toLowerCase()) ?
                    // typeof(listOfFiles.find((el) => el.name === "README.md")) === "undefined" ?

                    <>
                      <div className="flex flex-row justify-between bg-[#F8FAFC] py-[7px] px-[15px] mt-4">
                        <div className="flex   justify-between">
                          <div className="flex flex-row">
                            <DocumentIcon className="w-5" />
                            <p className="font-semibold text-base ml-2">README.md</p>
                          </div>

                        </div>

                      </div>

                      <div className="border-[1px] pb-[45px] px-8">
                        <p className="font-['Menlo'] pt-3">{JSON.stringify(readme).slice(1, -1)}</p>
                      </div>

                    </>
                    :
                    <div className="bg-[#E1F3FF] py-4 px-6 mt-[18px]">
                      <p className="font-semibold text-[18px] flex justify-start">У вас отсутсвует Readme файл</p>
                      <p className=" text-left font-normal text-[15px] ">Помогите людям лучше понять ваш репозиторий,
                        добавив в него README. Вы можете <span className="text-[#0066C3] font-semibold cursor-pointer" onClick={createReadMe} > создать README файл здесь.</span> </p>
                    </div>

                  }
                </div>


                <div className="ml-7 mt-6 w-1/4">
                  <p className="font-normal text-left text-[16px]">О репозитории</p>
                  <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-2">В данном репозитории отсутствует описание</p>

                  {releases.length ?
                    <></>
                    :
                    <div>
                      <p className="font-normal text-left text-[16px] mt-6">Релизы</p>
                      <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-2">У вас пока нет ни одного релиза. Вы можете создать его в релизах
                        {/* <a href={`/user/repo/${id}/${projectName}/reliz`} className="font-bold hover:text-[#000]" onClick={() => { }}> здесь</a>. */}
                      </p>
                    </div>
                  }
                </div>
              </div>
            </div>
            :
            <Code path={path} setPath={setPath} id={id} projectName={projectName} selectedBranch={selectedBranch} setType={setType} setChanges={setChanges} changes={changes} />
            // <>asdasd</>

          }
        </div>
      </div>



      <Modal
        open={showModalBranch}
        title={null}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-5">
          <p className="text-2xl block mb-2">Создать новую ветку</p>

          <div className="flex flex-row items-center gap-2">
            <p>Из ветки:</p>
            <Dropdown className="flex justify-start bg-[#F5F5F5] rounded-full px-4 py-1"
              menu={{
                items: onlyBranches,
                onClick: clickHandlerNewBranch
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {newBranchFrom}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>


          </div>

          <Input placeholder="Branch name"
            className=""
            size="large"
            value={newBranchName}
            onChange={(e) => {
              setNewBranchName(e.target.value)
            }} />

        </div>
        <div className="flex justify-between items-center gap-4 mt-4">
          <Button
            size="md"
            className="w-full"
            onClick={handleModalBranchOk}
          >
            Создать
          </Button>
          <Button
            size="md"
            color="secondary"
            className="w-full"
            onClick={() => { setShowModalBranch(false) }}
          >
            Отмена
          </Button>
        </div>
      </Modal>




      <Modal
        open={showModalFile}
        title={null}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-5">
          <p className="text-2xl block mb-2">Создать новый файл или папку</p>
          <Radio.Group options={options} onChange={onChange3} value={fileOrFolder} optionType="button" />
          <Input placeholder={fileOrFolder === "Файл" ? "File name" : "Folder name"}
            className=""
            size="large"
            value={newFileName}
            onChange={(e) => {
              setNewFileName(e.target.value)
              
            }} />

        </div>
        <div className="flex justify-between items-center gap-4 mt-4">
          <Button
            size="md"
            className="w-full"
            onClick={handleModalFileOk}
          >
            Создать
          </Button>
          <Button
            size="md"
            color="secondary"
            className="w-full"
            onClick={() => { setShowModalFile(false) }}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    </>
  )
}