import * as React from "react"

const LoginLayout = (props) => {
  return (
    <div className="h-screen w-full">
      <div className="flex">
        <div className='w-1/2 flex h-screen justify-center items-center flex-col bg-slate-50 px-14'>
          <span className='drop-shadow-md text-primary-violet font-bold font-oxanium text-[60px] text-shadow'>{`<ONTORY/>`}</span>
          <span className='text-center text-xl text-slate-400 leading-8'>Комплексная система для управления жизненным<br/>циклом программного обеспечения (ПО) при<br/>разработке продуктов и решений в IT-отрасли</span>
        </div>
        <div className='w-1/2 flex h-screen'>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default LoginLayout