import { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook'
import { useGit } from '../../hooks/git.hook'
import { Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Button } from '../Button'
import { useParams } from 'react-router-dom'
import { LineMenu } from '../LineMenu'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import {
  ArrowNarrowRightIcon,
  ArrowNarrowLeftIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  UserCircleIcon
} from '@heroicons/react/outline'
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";
import { useMessage } from '../../hooks/message.hook'


export const Merj = ({ projectTree }) => {
  const [diff, setDiff] = useState("");
  const [diffFromApi, setDiffFromApi] = useState("")
  const message = useMessage()
  const pic = <ArrowNarrowLeftIcon />
  moment.updateLocale('ru', { week: { dow: 1 } })
  const { TextArea } = Input;
  const params = useParams()
  const type = params.typeparam
  const id = params.id
  const target = params.target
  const source = params.source
  const [newMergeRequest, setNewMergeRequest] = useState(false)
  const [sourceBranch, setSourceBranch] = useState("")
  const [targetBranch, setTargetBranch] = useState("")
  const [mergeRequests, setMergeRequests] = useState([])
  const [mergeRequestsFiltered, setMergeRequestsFiltered] = useState([])
  const auth = useSelector(state => state.auth)
  const { gitRequest } = useHttp()
  const { fixAvatar, sendMergeRequest, getRepoBranches, getMergeRequest, mergeAccept, getDiffBetweenBranches, mergeDecline } = useGit()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [branches, setBranches] = useState([])
  const [changes, setChanges] = useState(0)
  const [mergeMenu, setMergeMenu] = useState(
    [
      { id: 0, text: 'Коммиты', active: true },
      { id: 1, text: 'Изменения', active: false }

    ]
  )
  const [activeMergeMenu, setActiveMergeMenu] = useState('Коммиты')


  const clickHandlerMergeMenu = (id) => {
    setMergeMenu(prev => [...prev.map(el => {
      el.active = false
      if (el.id === id) el.active = true
      if (el.active == true) {
        setActiveMergeMenu(el.text)
      }
      return el
    })])
  }

  const clickHandlerSourceBranch = ({ key }) => {
    setSourceBranch(key)
    setNewMergeRequest(false)
  };

  const clickHandlerTargetBranch = ({ key }) => {
    setTargetBranch(key)
    setNewMergeRequest(false)

  };

  const areaChangeHandler = (value) => {
    console.log(value)
    setDescription(value)
  }

  useEffect(() => {
    if (diffFromApi.length) {
      let diffHtml = Diff2Html.html(
        diffFromApi,
        {
          drawFileList: false,
          outputFormat: "side-by-side",
          fileListToggle: false,
          fileListStartVisible: false,
          fileContentToggle: true,
          highlightCode: true,
          matching: "lines",
          synchronisedScroll: true,
          highlight: true,
          renderNothingWhenEmpty: true,
          synchronisedScroll: true
        }
      );
      setDiff(diffHtml);
    }
  }, [diffFromApi]);

  useEffect(() => {
    (async () => {
      setMergeRequests(await getMergeRequest(id, auth))
      setBranches(await getRepoBranches(id, auth))
    })()
  }, [])


  useEffect(() => {
    (async () => {
      setMergeRequests(await getMergeRequest(id, auth))
    })()
  }, [newMergeRequest, changes])



  useEffect(() => {
    setMergeRequestsFiltered(mergeRequests.filter(function (mergeRequest) {
      if (mergeRequest.target_branch == targetBranch && mergeRequest.source_branch == sourceBranch) {
        return mergeRequest
      }
    }))
  }, [mergeRequests])

  useEffect(() => {
    setTargetBranch(target ? target : branches[0]?.label)
    setSourceBranch(source ? source : branches[0]?.label)
  }, [branches])

  useEffect(() => {
    setMergeRequestsFiltered(mergeRequests.filter(function (mergeRequest) {
      if (mergeRequest.target_branch == targetBranch && mergeRequest.source_branch == sourceBranch) {
        return mergeRequest
      }
    }))
  }, [sourceBranch, targetBranch])

  useEffect(() => {
    (async () => {
      setDiffFromApi(await getDiffBetweenBranches(id, sourceBranch, targetBranch, auth))
    })()
  }, [sourceBranch, targetBranch])




  const mergeAcceptHandler = async (id, mergeReqId, merge_commit_message, auth) => {
    message(await mergeAccept(id, mergeReqId, merge_commit_message, auth))
    setChanges(changes + 1)
  }

  const mergeDeclineHandler = async (mergeReqId) => {
    message(await mergeDecline(id, mergeReqId, auth))
    setChanges(changes + 1)
  }



  const Commit = ({ date, title, merge_user, avatar, state, iid }) => {
    return (
      <>

        <div className="flex flex-row text-[#94A3B8] gap-2 mt-[12px]" >
          <CheckCircleIcon className="w-6 " /> <p>Коммиты {moment(date).format("DD MMM, YYYYг.")}</p>
        </div>

        <div className="flex flex-row">

          <div className="border-l-[1px] ml-3"></div>

          <div className=' border-[1px] w-full py-2 shadow ml-8 flex flex-row items-center'>
            <div className='flex flex-col mt-2 px-6  w-full'>
              <div className="">
                <p>{title}</p>
              </div>
              <div className="flex flex-row items-center gap-1">
                {avatar ?
                  <img src={avatar} className="rounded-full w-[18px] h-[18px]"></img>
                  :
                  <UserCircleIcon className="w-[18px] h-[18px]" />
                }
                <p>{merge_user} {state}</p>
              </div>
            </div>

            {state === "opened" ?
              <div className='flex flex-row gap-4 pr-4'>
                <Button
                  type="primary"
                  size="xs"
                  className=""
                  onClick={() => {
                    mergeAcceptHandler(id, iid, "merge_commit_message", auth)
                  }}
                // disabled={loading}
                >
                  Принять
                </Button>

                <Button
                  color="secondary"
                  size="xs"
                  className=""
                  onClick={() => {
                    mergeDeclineHandler(iid)
                  }}
                // disabled={loading}
                >
                  Отклонить
                </Button>
              </div>
              :
              <></>

            }

          </div>
        </div>








      </>
    )
  }

  const CreateMergeRequest = () => {
    return (
      <>
        <div className="flex flex-row">
          <div className='flex flex-col items-center'>
            <ArrowNarrowLeftIcon className="w-6 cursor-pointer text-[#94A3B8] hover:text-[#000] mb-1" onClick={() => { setNewMergeRequest(false) }} />
            <img src={"https://git.app.ontory.ru" + auth.user.avatar_url.slice(25)} className="w-10 h-10 rounded-full"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = auth.user.avatar_url;
              }}
            ></img>
          </div>
          <div className="flex flex-row w-full ml-2">
            <div className="flex flex-col w-full border-[1px] p-2 rounded-lg">
              <Input placeholder="Title" className="mb-2"
                value={title}
                onChange={(e) => setTitle(e.target.value)} />

              <TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Leave a comment"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  const Switcher = () => {
    switch (activeMergeMenu) {

      case "Коммиты":
        return (
          <>
            {!newMergeRequest ?
            // h-[calc(100vh-180px)]
              <div className=' h-[calc(100vh-434px)] w-full overflow-auto scrollbar-hide md:scrollbar-default '>
                <ul>{listOfMergeRequests}</ul>
              </div>
              :
              <>
                {CreateMergeRequest()}
              </>
            }
          </>
        )



      case "Изменения":
        return (
          <div className=' h-[calc(100vh-450px)] w-full overflow-auto scrollbar-hide md:scrollbar-default mt-4'>
            <div id="code-diff" className='relative' dangerouslySetInnerHTML={{ __html: diff }}></div>
          </div>
        )







      default:
        return (
          <>
            {!newMergeRequest ?
              <ul>{listOfMergeRequests}</ul>
              :
              <>
                {CreateMergeRequest()}
              </>
            }
          </>
        )
    }
  }

  const listOfMergeRequests = mergeRequestsFiltered.map((mergeRequest, idx) =>
    < li key={idx} >
      <Commit date={mergeRequest.created_at}
        title={mergeRequest.title}

        merge_user={mergeRequest.author.username}
        iid={mergeRequest.iid}
        avatar={mergeRequest.author.avatar_url}
        state={mergeRequest.state ? mergeRequest.state : "state"} />
    </li >
  );


  async function createMergeReq(id, sourceBranch, targetBranch, title, description, auth) {
    message(await sendMergeRequest(id, sourceBranch, targetBranch, title, description, auth))
    setActiveMergeMenu('Коммиты')
    setNewMergeRequest(false)
  }

  const createHandler = async () => {
    setActiveMergeMenu('Коммиты')
    newMergeRequest && sourceBranch !== targetBranch ?


      await createMergeReq(id, sourceBranch, targetBranch, title, description, auth)


      : setNewMergeRequest(true)
  }



  return (
    <>
      <div className="flex justify-center ">
        <div className="pt-[27px] w-3/4  flex-col ">
          <div className="py-0 ">
            <p className="font-semibold text-[20px] flex justify-start">Создание мердж реквеста</p>
            <p className=" text-left font-normal text-[15px]  mb-[7px]">Сравнивайте и обсуждайте изменения между ветками, прежде чем изменения окажутся в проде</p>
            <div className="flex flex-row gap-4 mt-4">
              <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 "
                menu={{
                  items: branches,
                  onClick: clickHandlerSourceBranch
                }}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {sourceBranch}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              <ArrowNarrowRightIcon className="w-[24px]" />
              <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 "
                menu={{
                  items: branches,
                  onClick: clickHandlerTargetBranch
                }}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {targetBranch}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div className='border-b pt-4'></div>
            {sourceBranch === targetBranch ?
              <div className="bg-[#E1F3FF] py-4 px-6 mt-[18px] text-[#CA8A04] bg-[#FFFBEB] border-[#FEF08A] border-[1px] rounded">
                <div className="flex flex-row items-center">
                  <ExclamationCircleIcon className="w-8 " />
                  <div>
                    <p className="font-semibold text-[20px] flex justify-start ml-2">Ветки совпадают</p>
                  </div>
                </div>
                <p className=" text-left ml-10 font-normal text-[15px] ">выберите разные ветки чтобы обсудить с командой внесенные изменения</p>
              </div>
              :
              <div className="mt-[25px]">
                {!newMergeRequest ?
                  <LineMenu items={mergeMenu}
                    onClick={clickHandlerMergeMenu}
                  />
                  :
                  <></>
                }
                {Switcher()}
              </div>
            }
          </div>



          <Button
            type="primary"
            size="md"
            className=" py-3 mt-5 px-[55px]"
            onClick={() => {
              createHandler()
            }}
          // disabled={loading}
          >
            Создать мердж реквест
          </Button>



        </div>
      </div>
    </>
  )
}