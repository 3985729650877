


export const Zip = ({ }) => {

  

    return (
      <>
        
<svg version="1.1" id="Capa_1" x="0px" y="0px"
	 width="24px" height="24px" viewBox="0 0 595.396 595.396" 
	 >
<g>
	<g>
		<g>
			<path d="M122.666,594.896H472.73c32.059,0,58.14-26.082,58.14-58.141V146.093c0-15.417-5.973-29.942-16.817-40.899
				l-86.556-87.453c-5.435-5.49-11.806-9.783-18.936-12.758C401.433,2.009,393.899,0.5,386.175,0.5H122.666
				c-32.058,0-58.14,26.082-58.14,58.14v478.116C64.526,568.815,90.608,594.896,122.666,594.896z M479.55,131.234h-63.638
				c-4.089,0-7.932-1.593-10.822-4.485c-2.891-2.893-4.48-6.737-4.478-10.826l0.046-64.399L479.55,131.234z M107.366,58.64
				c0-8.45,6.85-15.3,15.3-15.3h40.033l0,0v18.231c0,8.45,6.85,15.3,15.3,15.3h12.24c8.45,0,15.3-6.85,15.3-15.3V43.34l0,0h152.285
				l-0.052,72.552c-0.012,15.538,6.031,30.147,17.014,41.138c10.983,10.991,25.589,17.043,41.126,17.043h72.118v362.683
				c0,8.449-6.851,15.3-15.3,15.3H122.666c-8.45,0-15.3-6.851-15.3-15.3V58.64z"/>
			<path d="M472.73,595.396H122.666c-32.334,0-58.64-26.306-58.64-58.641V58.64C64.026,26.306,90.332,0,122.666,0h263.509
				c7.791,0,15.388,1.521,22.579,4.521c7.191,3,13.616,7.33,19.099,12.868l86.556,87.453c10.938,11.051,16.962,25.701,16.962,41.251
				v390.663C531.37,569.091,505.064,595.396,472.73,595.396z M122.666,1c-31.783,0-57.64,25.857-57.64,57.64v478.116
				c0,31.783,25.857,57.641,57.64,57.641H472.73c31.782,0,57.64-25.857,57.64-57.641V146.093c0-15.285-5.921-29.685-16.673-40.548
				l-86.556-87.453c-5.389-5.443-11.704-9.699-18.772-12.648C401.3,2.495,393.833,1,386.175,1H122.666z M472.73,552.557H122.666
				c-8.712,0-15.8-7.088-15.8-15.8V58.64c0-8.712,7.088-15.8,15.8-15.8h40.533v18.731c0,8.161,6.639,14.8,14.8,14.8h12.24
				c8.161,0,14.8-6.639,14.8-14.8V42.84h153.285v0.5l-0.052,72.552c-0.012,15.403,5.979,29.887,16.867,40.784
				c10.889,10.896,25.369,16.897,40.772,16.897h72.618v363.183C488.53,545.469,481.442,552.557,472.73,552.557z M122.666,43.84
				c-8.161,0-14.8,6.639-14.8,14.8v478.117c0,8.16,6.639,14.8,14.8,14.8H472.73c8.16,0,14.8-6.64,14.8-14.8V174.574h-71.618
				c-15.671,0-30.402-6.105-41.479-17.19c-11.078-11.086-17.172-25.821-17.16-41.492l0.052-72.052H206.039v17.731
				c0,8.712-7.088,15.8-15.8,15.8h-12.24c-8.712,0-15.8-7.088-15.8-15.8V43.84H122.666z M480.748,131.734h-64.836
				c-4.222,0-8.19-1.645-11.176-4.631s-4.627-6.957-4.624-11.18l0.047-65.613L480.748,131.734z M401.157,52.74l-0.045,63.184
				c-0.003,3.955,1.535,7.674,4.331,10.472s6.514,4.338,10.469,4.338h62.439L401.157,52.74z"/>
		</g>
		<g>
			<path d="M228.118,126.99h12.24c8.45,0,15.3-6.85,15.3-15.3v-18.23c0-8.45-6.85-15.3-15.3-15.3h-12.24
				c-8.451,0-15.3,6.85-15.3,15.3v18.23C212.817,120.14,219.667,126.99,228.118,126.99z"/>
			<path d="M240.357,127.49h-12.24c-8.712,0-15.8-7.088-15.8-15.8v-18.23c0-8.712,7.088-15.8,15.8-15.8h12.24
				c8.712,0,15.8,7.088,15.8,15.8v18.23C256.158,120.402,249.07,127.49,240.357,127.49z M228.118,78.659
				c-8.161,0-14.8,6.639-14.8,14.8v18.23c0,8.161,6.639,14.8,14.8,14.8h12.24c8.161,0,14.8-6.639,14.8-14.8v-18.23
				c0-8.161-6.639-14.8-14.8-14.8H228.118z"/>
		</g>
		<g>
			<path d="M177.871,177.236h12.24c8.45,0,15.3-6.85,15.3-15.3v-18.231c0-8.45-6.85-15.3-15.3-15.3h-12.24
				c-8.451,0-15.3,6.85-15.3,15.3v18.231C162.571,170.386,169.42,177.236,177.871,177.236z"/>
			<path d="M190.111,177.736h-12.24c-8.712,0-15.8-7.088-15.8-15.8v-18.231c0-8.712,7.088-15.8,15.8-15.8h12.24
				c8.712,0,15.8,7.088,15.8,15.8v18.231C205.911,170.648,198.823,177.736,190.111,177.736z M177.871,128.905
				c-8.161,0-14.8,6.639-14.8,14.8v18.231c0,8.161,6.639,14.8,14.8,14.8h12.24c8.161,0,14.8-6.639,14.8-14.8v-18.231
				c0-8.161-6.639-14.8-14.8-14.8H177.871z"/>
		</g>
		<g>
			<path d="M227.409,226.774h12.24c8.45,0,15.3-6.85,15.3-15.3v-18.231c0-8.45-6.85-15.3-15.3-15.3h-12.24
				c-8.45,0-15.3,6.85-15.3,15.3v18.231C212.109,219.924,218.959,226.774,227.409,226.774z"/>
			<path d="M239.649,227.274h-12.24c-8.712,0-15.8-7.088-15.8-15.8v-18.231c0-8.712,7.088-15.8,15.8-15.8h12.24
				c8.712,0,15.8,7.088,15.8,15.8v18.231C255.449,220.187,248.361,227.274,239.649,227.274z M227.409,178.443
				c-8.161,0-14.8,6.639-14.8,14.8v18.231c0,8.161,6.639,14.8,14.8,14.8h12.24c8.161,0,14.8-6.639,14.8-14.8v-18.231
				c0-8.161-6.639-14.8-14.8-14.8H227.409z"/>
		</g>
		<g>
			<path d="M190.465,227.128h-12.24c-8.45,0-15.3,6.85-15.3,15.3v18.231c0,8.45,6.85,15.3,15.3,15.3h12.24
				c8.45,0,15.3-6.85,15.3-15.3v-18.231C205.765,233.978,198.915,227.128,190.465,227.128z"/>
			<path d="M190.465,276.459h-12.24c-8.712,0-15.8-7.088-15.8-15.8v-18.231c0-8.712,7.088-15.8,15.8-15.8h12.24
				c8.712,0,15.8,7.088,15.8,15.8v18.231C206.265,269.371,199.177,276.459,190.465,276.459z M178.225,227.628
				c-8.161,0-14.8,6.639-14.8,14.8v18.231c0,8.161,6.639,14.8,14.8,14.8h12.24c8.161,0,14.8-6.639,14.8-14.8v-18.231
				c0-8.161-6.639-14.8-14.8-14.8H178.225z"/>
		</g>
		<g>
			<path d="M200.388,466.379h23.444c26.031,0,47.208-21.178,47.208-47.209v-77.029c0-26.031-21.178-47.208-47.208-47.208h-23.444
				c-26.031,0-47.209,21.177-47.209,47.208v77.029C153.179,445.201,174.357,466.379,200.388,466.379z M234.319,419.169
				c0,5.793-4.696,10.489-10.488,10.489h-23.444c-5.792,0-10.489-4.696-10.489-10.489v-9.791h44.421L234.319,419.169
				L234.319,419.169z M189.899,342.141c0-5.793,4.696-10.488,10.489-10.488h23.444c5.792,0,10.489,4.695,10.489,10.488v30.518
				h-44.421L189.899,342.141L189.899,342.141z"/>
			<path d="M223.832,466.879h-23.444c-26.307,0-47.709-21.402-47.709-47.709v-77.029c0-26.307,21.402-47.708,47.709-47.708h23.444
				c26.307,0,47.708,21.402,47.708,47.708v77.029C271.54,445.477,250.138,466.879,223.832,466.879z M200.388,295.432
				c-25.755,0-46.709,20.954-46.709,46.708v77.029c0,25.756,20.954,46.709,46.709,46.709h23.444
				c25.755,0,46.708-20.953,46.708-46.709v-77.029c0-25.755-20.953-46.708-46.708-46.708H200.388z M223.831,430.158h-23.444
				c-6.06,0-10.989-4.93-10.989-10.989v-10.291h45.421v10.291c0,2.936-1.143,5.695-3.218,7.771
				C229.525,429.015,226.766,430.158,223.831,430.158z M190.398,409.878v9.291c0,5.508,4.481,9.989,9.989,9.989h23.444
				c5.507,0,9.988-4.481,9.988-9.989l0-9.291H190.398z M234.82,373.158h-45.421v-31.018c0-2.936,1.143-5.694,3.218-7.771
				c2.075-2.075,4.835-3.218,7.77-3.218h23.444c6.059,0,10.989,4.93,10.989,10.988V373.158z M190.399,372.158h43.421v-30.018
				c0-5.508-4.481-9.988-9.989-9.988h-23.444c-5.508,0-9.989,4.48-9.989,9.988L190.399,372.158z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>


      </>
    )
  }


