import { useMessage } from '../../hooks/message.hook'
import { useState, useEffect } from 'react'
import { Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import { useSelector } from 'react-redux'
import { Button } from '../Button'
import { useGit } from '../../hooks/git.hook'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'
import { Zip } from './zip'
import { Modal } from 'antd'
import {
  TagIcon,
  ArrowNarrowLeftIcon,
} from '@heroicons/react/outline'
import { useHttp } from '../../hooks/http.hook'


export const Releases = ({ }) => {
  const message = useMessage()
  const { TextArea } = Input
  const params = useParams()
  const id = params.id
  const [releases, setReleases] = useState([])
  const auth = useSelector(state => state.auth)
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState('Выберите тэг')
  const { request } = useHttp()
  const [holdedTasks, setHoldedTasks] = useState([])
  const {
    fixAvatar,
    getRepoBranches,
    getReleases,
    getTags,
    createTag,
    createRelease,
    getCommits
  } = useGit()
  const [creatingRelease, setCreatingRelease] = useState(false)
  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState('Выберите ветку')
  const [selectedTagBranch, setSelectedTagBranch] = useState('Выберите ветку создания тэга')
  const [showM, setShowM] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [commits, setCommits] = useState([])

  const [jiraTasks, setJiraTasks] = useState([])

  const addTag = [
    {
      type: 'divider',
      name: ''
    },
    {
      key: 'Create new tag',
      name: 'Create new tag'
    }
  ]
  moment.updateLocale('ru', { week: { dow: 1 } })

  useEffect(() => {
    if (auth) {
      (async () => {
        setCommits(await getCommits(id, auth))
        setReleases(await getReleases(id, auth))
        setBranches(await getRepoBranches(id, auth))
        let tmpTags = await getTags(id, auth)
        tmpTags = [...tmpTags, ...addTag]
        setTags(tmpTags)
      })()
    }
  }, [auth, creatingRelease])


  // console.log("releases - - - ", releases)


  useEffect(() => {

    (async () => {
      const jira = await request('/api/jira/getProjectEpics', 'POST', { repoId: id })
      setJiraTasks(jira?.allTasks)
      // console.log(" JIRA _____", jira?.allTasks)


    })()

  }, [])









  const selectBranchHandler = ({ key }) => {
    setSelectedBranch(key)
  }

  const selectTagBranchHandler = ({ key }) => {
    setSelectedTagBranch(key)
  }

  const handleCancel = () => {
    setShowM(false)
    setNewTag("")
  }

  const handleModalOk = async () => {
    if (selectedTagBranch === "Выберите ветку создания тэга") {
      message("Необходимо выбрать ветку")
    } else {
      message(await createTag(id, auth, selectedTagBranch, newTag))
      setShowM(false)
      let tmpTags = await getTags(id, auth)
      tmpTags = [...tmpTags, ...addTag]
      setTags(tmpTags)
    }
  }

  const onClick = ({ key }) => {
    if (key === 'Create new tag') {
      setShowM(true)
    } else {
      setSelectedTag(key)
    }
  }

  const createReleaseHandler = async () => {
    if (selectedTag === "Выберите тэг" || selectedBranch === "Выберите ветку") {
      message("Необходимо выбрать тег и ветку")
    } else {
      message(await createRelease(id, auth, selectedBranch, title, `${description} [_[${holdedTasks.join(',')}]_]`, selectedTag))
      setHoldedTasks([])
      setCreatingRelease(false)

    }
  }

  const Release = ({ prevIdx, date, description, author, avatar, tag, commit, zip, tar }) => {
    if (prevIdx < 0) {
      prevIdx = 0
    }
    function chunkCommits() {
      // console.log("!--------!")
      // console.log("created_at", commit)
      // console.log("date", date)
      // console.log("releases[prevIdx].created_at", releases[prevIdx].created_at)
      // console.log("clear:", commits)

      let res = commits.filter(comm =>
        Math.floor(new Date(comm.created_at).getTime()) < Math.floor(new Date(date).getTime()) // оставляем коммиты до даты релиза
      )

      // res = res.filter(comm =>
      //   Math.floor(new Date(comm.created_at).getTime()) > Math.floor(new Date(releases.reverse()[prevIdx].created_at).getTime()) // оставляем коммиты которые позже предыдущего релиза
      // );

      res = res.filter(comm =>
        comm.title.includes("ON-1") // фильтруем по ключу
      )






      res = res.map(el => {
        return {
          title: el.title
        }
      })

      let resstr = ""
      for (let i = 0; i < res.length; i++) {
        resstr = resstr + "#" + res[i].title
      }

      return resstr
    }

    const Commit = ({ title, id }) => {
      return (
        <>
          <div>Jira: (<span>#{title}</span> )</div>
        </>
      )
    }

    // const listOfCommits = chunkCommits().map((commit, idx) =>
    //   < li key={idx} >
    //     <Commit
    //       title={commit.title}
    //       committer={commit.committer_name}
    //       id={commit.short_id}
    //     />
    //   </li>
    // )






    // Извлекаем то, что внутри [_[ и ]_]
    let match = description?.match(/\[_\[(.*?)\]_\]/)

    // Если есть совпадение, разбиваем строку на массив по запятым
    let tasksArray = match ? match[1].split(',') : []


    let filteredJira = jiraTasks?.filter(item => tasksArray?.includes(item.key))

    // console.log("++++++", description, "======", tasksArray)


    return (
      <>
        <div className="pt-[27px] w-full flex  flex-col">
          <div className="flex flex-row  justify-center">
            <div className="flex flex-col p-4 w-[250px] gap-1  ">
              <p className="font-semibold">{moment(date).format('DD MMM, YYYYг.')}</p>
              <div className="flex flex-row gap-2 text-[#94A3B8] w-[210px]">
                <img src={fixAvatar(avatar)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = auth.user.avatar_url
                  }}
                  className="w-6 rounded-full"></img>{author}
              </div>
              <div className="flex flex-row gap-2 text-[#94A3B8]"><TagIcon className="w-6" />{tag}</div>
              <div className="flex flex-row gap-2 text-[#94A3B8]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14 16">
                  <path fill="#94A3B8"
                    d="M10.86 7c-.45-1.72-2-3-3.86-3-1.86 0-3.41 1.28-3.86 3H0v2h3.14c.45 1.72 2 3 3.86 3 1.86 0 3.41-1.28 3.86-3H14V7h-3.14zM7 10.2c-1.22 0-2.2-.98-2.2-2.2 0-1.22.98-2.2 2.2-2.2 1.22 0 2.2.98 2.2 2.2 0 1.22-.98 2.2-2.2 2.2z" />
                </svg>
                {commit}</div>
            </div>
            <div className="flex flex-col w-full p-6 border-[1px] rounded-[8px] ">
              <p className="text-2xl font-semibold mb-8">{moment(date).format('DD MMM, YYYYг.')}</p>
              <p>{description?.replace(/\[_\[.*?\]_\]/, '')}</p>
              <p className="text-2xl font-semibold mb-4">Assets </p>
              <div className=" flex justify-between border-[1px] rounded-t py-1.5 px-4">
                <div className="flex flex-row  "><Zip className="w-6" />

                  <a className="text-[#4169CC] hover:text-[#000] font-semibold" href={fixAvatar(zip)}>
                  Исходный_код_{tag} (zip)</a>
                </div>
                <div className="font-semibold text-[#94A3B8]">{moment(date).format('DD MMM, YYYYг.')}</div>
              </div>
              <div className=" flex justify-between border-[1px] rounded-b py-1.5 px-4">
                <div className="flex flex-row  "><Zip className="w-6" />
                  <a className="text-[#4169CC] hover:text-[#000] font-semibold" href={fixAvatar(tar)}>
                  Исходный_код_{tag} (tar.gz)</a>
                </div>
                <div className="font-semibold text-[#94A3B8]">{moment(date).format('DD MMM, YYYYг.')}</div>
              </div>

              {filteredJira?.length > 0 && <div className="text-[15px] font-bold mt-[16px]">Исправления ошибок:</div>}
              


              <div className='flex flex-col gap-[6px] mt-[10px] items-start'>

                {filteredJira?.slice(0, 2).map((task, idx) => {
                  return (
                    <div className='bg-[#ECE8F7] px-[16px] py-[4px] text-[16px] rounded-full w-auto max-w-[500px] text-center' key={idx}
                      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      № {task?.key} - {task?.summary}
                    </div>
                  )
                })}

                {filteredJira?.length > 2 && (
                  <div className='bg-[#ECE8F7] px-[16px] py-[4px] text-[16px] rounded-full w-auto max-w-[300px] text-center'>
                    + {filteredJira.length - 2} задач
                  </div>
                )}

              </div>



              {/* <p className='pt-3' >JIRA:  <span className='text-[#4169CC]'>{chunkCommits()}</span></p> */}
            </div>
          </div>
        </div>
      </>
    )
  }

  const creatingReleaseHandler = () => {
    setCreatingRelease(true)
    setTitle("")
    setDescription("")
  }



  const holdTaskKey = (key) => {
    setHoldedTasks(prevTasks => {
      if (prevTasks.includes(key)) {

        return prevTasks.filter(taskKey => taskKey !== key)
      } else {

        return [...prevTasks, key]
      }
    })
  }



  const Create = () => {
    return (
      <>
        <div className="flex justify-center ">
          <div className="pt-[27px] w-3/4  flex-col ">
            <div className="flex flex-col  justify-start">
              <div className="flex flex-row">
                {releases.length ? <ArrowNarrowLeftIcon className="w-6 cursor-pointer text-[#94A3B8] hover:text-[#000] mr-6" onClick={() => { setCreatingRelease(false) }} /> : <></>}
                <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 mr-6"
                  menu={{
                    items: tags.map((tag, idx) => {
                      return {
                        label: tag.name,
                        key: tag.name,
                        type: tag?.type ? tag.type : null
                      }
                    }),
                    onClick,
                  }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {selectedTag}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                {/* <ArrowNarrowRightIcon className="w-[24px]" /> */}
                <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 "
                  menu={{
                    items: branches,
                    onClick: selectBranchHandler
                  }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {selectedBranch}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-4">Выберите существующий тег или
                создайте новый тег при публикации этого выпуска.</p>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-full">
                  <Input placeholder="Release title"
                    id="releaseTitle"
                    name="releaseTitle"
                    className="mt-4"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                  <TextArea className="mt-2 mb-[19px]"
                    placeholder="Describe this release"
                    autoSize={{
                      minRows: 10,
                      maxRows: 10,
                    }}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                  <Button
                    type="primary"
                    size="md"
                    className=" py-3 px-[50px] w-min "
                    onClick={createReleaseHandler}
                  >
                    Создать релиз
                  </Button>
                </div>



                <div className="ml-7 w-2/4 mt-3">
                  {/* <p className="font-semibold text-left text-[20px]">Предложения по тегам</p>
                  <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-2">Общепринятой практикой является
                    добавление к именам версий букв v. Некоторые хорошие имена тегов могут быть v1.0.0 или v2.3.4.</p>
                  <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-2">Если тег не предназначен для
                    использования в рабочей среде, добавьте предварительную версию после названия версии. Некоторые
                    хорошие предварительные версии могут быть v0.2.0-alpha или v5.9-beta.3.</p>
                  <p className="font-semibold text-left text-[20px] mt-6">Семантическое управление версиями</p>
                  <p className="font-normal text-left text-[16px] text-[#94A3B8] mt-2">Если вы новичок в выпуске
                    программного обеспечения, мы настоятельно рекомендуем прочитать о семантическом управлении
                    версиями</p> */}


                  <div className='flex flex-col gap-[2px]'>
                    <div className='text-[15px] font-semibold'>Отметьте  список задач, добавляемых в релиз</div>
                    {jiraTasks?.map((task, idx) => {
                      return (
                        <div onClick={() => { holdTaskKey(task?.key) }}
                          key={idx} className={`flex flex-col  py-[8px] gap-[2px] hover:bg-slate-50 
                          ${holdedTasks.includes(task?.key) ? 'bg-[#F0F6F0]' : ''}
                          cursor-pointer border-[1px] border-slate-100 rounded-md`}>


                          <div className='px-[9px] border-l-[3px] border-[#B2D1B5] text-[14px]'>№ {task?.key}</div>
                          <div className='px-[12px] text-[14px]'>{task?.summary}</div>
                          <div className={`px-[12px] text-[#8F8F8F] text-[11px]`}>{task?.description?.content[0]?.content[0]?.text}</div>


                        </div>
                      )
                    })}
                  </div>



                </div>




              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const listOfReleases = releases?.map((release, idx) =>
    < li key={idx}>
      <Release
        prevIdx={idx - 1}
        date={release?.released_at}
        description={release.description}
        author={release.author.username}
        avatar={release.author.avatar_url}
        tag={release.tag_name}
        commit={release.commit.short_id}
        zip={release.assets.sources[0].url}
        tar={release.assets.sources[1].url}
      />
    </li>
  )

  return (
    <>
      {
        releases.length && !creatingRelease ?
          <>
            <div className='flex flex-row  items-center justify-center'>
              <div className="w-3/4">
                <div className='flex flex-row'>
                  <div className=" h-[calc(100vh-150px)]  overflow-auto w-full scrollbar-hide md:scrollbar-default mb-10">
                    <ul>{listOfReleases}</ul>
                  </div>
                  <Button
                    type="primary"
                    size="md"
                    className=" py-3 px-[50px] mt-[27px] ml-2"
                    onClick={() => { creatingReleaseHandler() }}
                  >
                    Создать релиз
                  </Button>
                </div>
              </div>
            </div>
          </>
          :
          <>
            {Create()}
          </>
      }

      <Modal
        open={showM}
        title={null}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <div className="">
          <p className="text-2xl block mb-2 mb-2">Создать новый тэг</p>
          <div className='mt-[20px]'>
            <Dropdown className=" bg-[#F5F5F5] rounded-full px-4 py-1 "
              menu={{
                items: branches,
                onClick: selectTagBranchHandler
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedTagBranch}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <Input placeholder="Tag name"
            className="mt-5"
            size="large"
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value)

            }} />
        </div>
        <div className="flex justify-between items-center gap-4 mt-4">
          <Button
            size="md"
            className="w-full"
            onClick={handleModalOk}
          >
            Создать
          </Button>
          <Button
            size="md"
            color="secondary"
            className="w-full"
            onClick={() => {
              setShowM(false)
              setNewTag("")
            }}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    </>
  )
}