import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../components/Button'
import {
  AcademicCapIcon,
} from '@heroicons/react/outline'




export const Main = () => {
  const auth = useSelector(state => state.auth)
  const { loading } = useHttp()
  const history = useNavigate()
  const [repos, setRepos] = useState([])
  const { gitRequest } = useHttp()
  const [projects, setProjects] = useState([])
  const [selectedPr, setSelectedPr] = useState("")

  useEffect(() => {
    try {
      (async () => {
        const getUserProject = await gitRequest(`/api/v4/users/${auth.user.id}/projects`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setProjects(getUserProject.map((project, idx) => {
          return {
            name: project.name,
            id: project.id
          }
        })
        )
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])

  const Project = ({ name, id }) => {
    return (
      <>
        <div className='inline-flex mt-[5px] font-normal text-base hover:text-[#94A3B8] cursor-pointer' onClick={() => {
          setSelectedPr(name)
          history(`/user/project/${id}/byproject`)
        }}>
          <AcademicCapIcon className='w-[24px]' /> <p className='ml-[7px]'>{name}</p>
        </div>
      </>
    )
  }


  const listOfProject = projects.map((project, idx) =>
    <li key={idx}><Project name={project.name} id={project.id} /></li>
  );

  const Projects = () => {
    return (
      <>
        <div className="flex flex-col w-full">
          <div>
            <p className='font-normal text-base'>Список проектов</p>
            <ul className='mt-3'>{listOfProject}</ul>
          </div>

          {projects.length ?
          
            <div className='mt-[30px] w-full flex justify-center'>

              <Button
                size="small"
                color="secondary"
                className={"w-full"}
                onClick={() => { history('/project/create') }}
                disabled={loading}
              >
                Создать новый проект
              </Button>
              
            </div>
            :
            <div className='mt-[30%] flex flex-col items-center gap-6 px-0 w-full'>
              <span className="text-center">У вас нет ни одного проекта, для<br />начала работы вам необходимо<br />создать его.</span>
              <div className='mt-[30px] w-full flex justify-center'>

              <Button
                size="small"
                color="secondary"
                className={"w-full"}
                onClick={() => { history('/project/create') }}
                disabled={loading}
              >
                Создать новый проект
              </Button>
              
            </div>
            </div>
          }
        </div>

      </>
    )
  }

 


  return (
    <HScreen setRepos={setRepos}
    >
      <div className="flex h-screen">
        <div className="flex w-1/4 px-6 py-4">
          <Projects projects={[]} />
        </div>
        <div className="flex w-3/4 py-4 px-[54px] bg-slate-50">
          <div className="flex flex-col w-full gap-[40px]">
            <div className="flex flex-col gap-4">
            </div>
          </div>
        </div>
      </div>
    </HScreen>
  )
}